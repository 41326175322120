import React, { useCallback, useEffect, useState } from 'react'

import { flyingAreaServices } from '../../../services/flyingAreaServices';

import KML from 'react-leaflet-kml'

import { hashString } from '../../../utils';

import useErrorHandler from '../../../hooks/useErrorHandler';
import useMapFilters from '../../../hooks/useMapFilters';

function AreaBoundaryLayer() {
    const [kmlList, setKmlList] = useState([]);

    const { errorHandler } = useErrorHandler();
    const { filters } = useMapFilters();

    const getAllAreaBoundaryForMap = useCallback(async (currentFilters) => {
        try {
            const res = await flyingAreaServices.getAllAreaBoundaryForMap({ ...currentFilters, onlyGeoJson: 1, checkStatus: 1 });
            console.log(res);
            if (res.status === 200) {
                const parser = new DOMParser();
                setKmlList(res.data.data.map(currentData => {
                    const kmlData = parser.parseFromString(currentData.kml, "text/xml");
                    return { ...currentData, kml: kmlData };
                }));
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: getAllAreaBoundaryForMap',
                status,
                data.message
            );
        }
    }, []);

    useEffect(() => {
        getAllAreaBoundaryForMap(filters);
    }, [filters]);

    return (
        <div>
            {kmlList.map(data => (
                <KML key={hashString(JSON.stringify(data.geoJson))} kml={data.kml} />
            ))}
        </div>
    )
}

export default AreaBoundaryLayer