import React, { useCallback, useEffect, useState } from 'react'
import './ManageFlightImage.css'

import { useNavigate } from 'react-router-dom';

import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material';

import DataTable from '../../../components/DataTable/DataTable';
import DeleteButton from '../../../components/TableButton/DeleteButton';
import EditButton from '../../../components/TableButton/EditButton';
import OptionButton from '../../../components/OptionButton/OptionButton';
import ExcelButton from '../../../components/ExcelButton/ExcelButton';
import StatusButton from '../../../components/TableButton/StatusButton';

import { flightImageServices } from '../../../services/flightImageServices';
import { flyingAreaServices } from '../../../services/flyingAreaServices';

import useDataTable from '../../../hooks/useDataTable';
import useErrorHandler from '../../../hooks/useErrorHandler';

const columns = [
    {
        field: 'row_num',
        headerName: 'ลำดับ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
    },
    {
        field: 'target_area',
        headerName: 'พื้นที่เป้าหมาย',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
    },
    {
        field: 'target_type_name',
        headerName: 'ประเภทพื้นที่เป้าหมาย',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
        valueGetter: (param) => param.row.TargetType.target_type_name
    },
    {
        field: 'table_status',
        headerName: 'สถานะ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
        renderCell: (param) => <StatusButton data={param.row} service={flyingAreaServices.updateFlyingAreaStatus} />
    },
    {
        field: 'edit',
        headerName: 'แก้ไข',
        headerAlign: 'center',
        type: 'actions',
        sortable: false,
        flex: 1,
        renderCell: (param) => <EditButton id={param.row.id} formURL="overview/flightImage-form" />
    },
    {
        field: 'delete',
        type: 'actions',
        sortable: false,
        flex: 1,
        renderCell: (param) => <DeleteButton id={param.row.id} service={flightImageServices.deleteFlightImageByFlyingAreaId} />
    },
];

const initFilters = {
    search: '',
    dateFrom: '',
    dateTo: '',
}

function ManageFlightImage() {
    const navigate = useNavigate();

    const { paginationModel, resetPage } = useDataTable();
    const { errorHandler } = useErrorHandler();

    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [filters, setFilters] = useState(initFilters);
    const [paginationfilters, setPaginationFilters] = useState(initFilters);

    const getAllFlyingAreaWithFlightImage = useCallback(async (currentFilters) => {
        try {
            const res = await flightImageServices.getAllFlyingAreaWithFlightImage(currentFilters);
            console.log(res);
            if (res.status === 200) {
                setRows(res.data.data);
                setRowCount(res.data.rowCount);
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: getAllFlyingAreaWithFlightImage',
                status,
                data.message
            );
        }
    }, []);

    useEffect(() => {
        getAllFlyingAreaWithFlightImage({ ...paginationfilters, ...paginationModel });
    }, [paginationModel.page, paginationfilters])

    const handleFilterChange = ({ target }) => {
        const { name, value } = target;
        console.log(value);

        setFilters(prev => {
            return {
                ...prev,
                [name]: value,
            }
        });
    }

    const handleSearchClick = () => {
        console.log(filters);
        setPaginationFilters({ ...filters });
        resetPage();
    }

    const handleClearClick = () => {
        setFilters({ ...initFilters });
        setPaginationFilters({ ...initFilters });
        resetPage();
    }

    const handleAddClick = () => navigate('/dashboard/overview/flightimage-form');

    return (
        <div className="manage-flight-image">
            <Grid container className="filters" gap={1}>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>ค้นหา</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            name="search"
                            value={filters.search}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                </Grid>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>วันที่เพิ่ม</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateFrom"
                            value={filters.dateFrom}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>From</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateTo"
                            value={filters.dateTo}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>To</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className="options">
                <Grid item md={1}></Grid>
                <Grid
                    item xs={12} md={6}
                    sx={{
                        '& button': { width: '25%' },
                        '& :not(:nth-of-type(1))': { ml: 2 }
                    }}
                >
                    <OptionButton
                        text='ค้นหา'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleSearchClick}
                    />
                    <OptionButton
                        text='ล้าง'
                        bgColor='var(--options-btn-bg-color-2)'
                        hoverColor='var(--options-btn-bg-hover-2)'
                        textColor='gray'
                        onClick={handleClearClick}
                    />
                    <OptionButton
                        text='เพิ่ม'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleAddClick}
                    />
                </Grid>

                <ExcelButton modelName="FlightImage" importCallback={handleClearClick} />
            </Grid>

            <DataTable
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                pageProps={paginationModel}
            />

        </div>
    )
}

export default ManageFlightImage