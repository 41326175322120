import React, { useState } from 'react';
import './ManageDroneSize.css';

import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material';

import DataTable from '../../../components/DataTable/DataTable';
import ExcelButton from '../../../components/ExcelButton/ExcelButton';
import FormModal from '../../../components/FormModal/FormModal';
import OptionButton from '../../../components/OptionButton/OptionButton';
import OverviewEditButton from '../../../components/OverviewEditButton/OverviewEditButton';
import DeleteButton from '../../../components/TableButton/DeleteButton';
import StatusButton from '../../../components/TableButton/StatusButton';

import { droneSizeServices } from '../../../services/droneSizeServices';

import useDataTable from '../../../hooks/useDataTable';
import useDroneSize from '../../../hooks/useDroneSize';

const columns = [
    {
        field: 'row_num',
        headerName: 'ลำดับ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 0.4,
    },
    {
        field: 'size_name',
        headerName: 'ขนาดอากาศยาน',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
    },
    {
        field: 'table_status',
        headerName: 'สถานะ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 0.3,
        renderCell: (param) => <StatusButton data={param.row} service={droneSizeServices.updateStatus} />
    },
    {
        field: 'edit',
        headerName: 'แก้ไข',
        headerAlign: 'center',
        type: 'actions',
        sortable: false,
        flex: 0.3,
        renderCell: (param) => (
            <OverviewEditButton
                id={param.row.id}
                modalTitle={modalTitle}
                fieldName="size_name"
                findById={droneSizeServices.getDroneSizeById}
                service={droneSizeServices.updateDroneSize}
            />
        )
    },
    {
        field: 'delete',
        type: 'actions',
        sortable: false,
        flex: 0.3,
        renderCell: (param) => <DeleteButton id={param.row.id} service={droneSizeServices.deleteDroneSizeById} />
    },
];

const initFilters = {
    search: '',
    dateFrom: '',
    dateTo: '',
}

const modalTitle = 'ขนาดอากาศยาน';

function ManageDroneSize() {
    const { paginationModel, resetPage } = useDataTable();

    const [filters, setFilters] = useState(initFilters);
    const [paginationfilters, setPaginationFilters] = useState(initFilters);

    const [openModal, setOpenModal] = useState(false);

    const { droneSizeList: rows, rowCount, getAllDroneSize } = useDroneSize(
        { ...paginationfilters, ...paginationModel },
        [paginationModel.page, paginationfilters]
    );

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleFilterChange = ({ target }) => {
        const { name, value } = target;
        console.log(value);

        setFilters(prev => {
            return {
                ...prev,
                [name]: value,
            }
        });
    }

    const handleSearchClick = () => {
        console.log(filters);
        setPaginationFilters({ ...filters });
        resetPage();
    }

    const handleClearClick = () => {
        setFilters({ ...initFilters });
        setPaginationFilters({ ...initFilters });
        resetPage();
    }

    const handleAddClick = async () => {
        await getAllDroneSize({ ...paginationfilters, ...paginationModel });
        handleOpenModal();
    }

    return (
        <div className="manage-drone-size">
            <Grid container className="filters" gap={1}>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>ค้นหา</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            name="search"
                            value={filters.search}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                </Grid>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>วันที่เพิ่ม</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateFrom"
                            value={filters.dateFrom}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>From</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateTo"
                            value={filters.dateTo}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>To</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className="options">
                <Grid item md={1}></Grid>
                <Grid
                    item xs={12} md={6}
                    sx={{
                        '& button': { width: '25%' },
                        '& :not(:nth-of-type(1))': { ml: 2 }
                    }}
                >
                    <OptionButton
                        text='ค้นหา'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleSearchClick}
                    />
                    <OptionButton
                        text='ล้าง'
                        bgColor='var(--options-btn-bg-color-2)'
                        hoverColor='var(--options-btn-bg-hover-2)'
                        textColor='gray'
                        onClick={handleClearClick}
                    />
                    <OptionButton
                        text='เพิ่ม'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleAddClick}
                    />
                </Grid>

                <ExcelButton modelName="DroneSize" importCallback={handleClearClick} />
            </Grid>

            <DataTable
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                pageProps={paginationModel}
            />

            {openModal && (
                <FormModal
                    openModal={openModal}
                    onClose={handleCloseModal}
                    modalTitle={modalTitle}
                    fieldName="size_name"
                    service={droneSizeServices.createDroneSize}
                    onDataChange={() => getAllDroneSize({ ...paginationfilters, ...paginationModel })}
                />
            )}
        </div>
    )
}

export default ManageDroneSize