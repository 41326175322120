import React, { useState } from 'react'

import { 
    Button, Card, CardContent, CardHeader, 
    Checkbox, Collapse, FormControl, FormControlLabel, 
    Grid, MenuItem, Select, Tab, Tabs, TextField, Typography 
} from '@mui/material';
import { Menu } from '@mui/icons-material';

import { useLocation, useSearchParams } from 'react-router-dom';

import useMapFilters from '../../hooks/useMapFilters';

function MapControlPanel({ handleLayerClick, optionMenu }) {
    const location = useLocation();
    const isMobile = location.pathname.includes('mapForMobile');
    const [searchParams] = useSearchParams({ mapLayer: 'ภาพถ่ายทางอากาศ' });
    
    const { setFilters, flightPlanStatusList, setFlightPlanStatusList, setTargetTypeList } = useMapFilters();
    
    const [expanded, setExpanded] = useState(!isMobile);
    const [tabIndex, setTabIndex] = useState(optionMenu.findIndex(menu => menu.label === searchParams.get('mapLayer')));

    const handleExpandClick = () => setExpanded(!expanded);

    const handleTabChange = (event, newValue) => setTabIndex(newValue);

    const handleFilterChange = ({ target }) => {
        const { name, value, type, checked } = target;
        let currentValue;
        console.log(value);

        if (type === "checkbox") {
            
            if (name === "flightPlanStatus") {
                setFlightPlanStatusList(prev => {
                    const index = prev.findIndex(r => r.value === value);
                    const newFlightPlanStatus = prev[index];

                    newFlightPlanStatus.checked = checked;
                    
                    prev[index] = newFlightPlanStatus;
        
                    return [...prev]
                });
            }

            if (name === "targetType") {
                setTargetTypeList(prev => {
                    const index = prev.findIndex(r => r.id === Number(value));
                    const newTargetType = prev[index];

                    newTargetType.checked = checked;
                    
                    prev[index] = newTargetType;
        
                    return [...prev]
                });
            }
        }
        
        setFilters(prev => {

            currentValue = value;

            if (type === "checkbox") {

                if (name === "flightPlanStatus") {
                    const newFlightPlanStatus = checked 
                        ? [...prev.flightPlanStatus, value] 
                        : prev.flightPlanStatus.filter(fs => fs !== value)
                    
                    currentValue = newFlightPlanStatus;
                }
    
                if (name === "targetType") {
                    const newTargetType = checked 
                        ? [...prev.targetType, value] 
                        : prev.targetType.filter(tt => tt !== value)
                    
                    currentValue = newTargetType;
                }
            }

            return {
                ...prev,
                [name]: currentValue,
            }
        });
    }
    
    return (
        <Card sx={{ width: expanded ? 'auto' : 'fit-content' }} className="options">
            <CardHeader
                sx={{ p: 0, '& .MuiCardHeader-avatar': { mr: 0 } }}
                avatar={
                    <Button onClick={handleExpandClick} size="large" sx={{ color: "gray" }}>
                        <Menu />
                    </Button>
                }
            />

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent sx={{ p: 1 }} className="layers">
                    <Typography className="header" px={1}>ประเภทแผนที่</Typography>
                    <Tabs
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        value={tabIndex}
                        onChange={handleTabChange}
                    >
                        {optionMenu.map(
                            (option) =>
                                <Tab
                                    key={option.label}
                                    sx={{ fontWeight: 700 }}
                                    icon={<img src={option.image} alt="" width={90} />}
                                    label={option.label}
                                    onClick={() => handleLayerClick(option.label)}
                                />
                        )}
                    </Tabs>
                </CardContent>
                <CardContent className="content-container">

                    {searchParams.get("mapLayer") === "เส้นทางบิน" && (
                        <StatusFilter 
                            flightPlanStatusList={flightPlanStatusList}
                            handleFilterChange={handleFilterChange}
                        />
                    )}

                    {searchParams.get("mapLayer") !== "เส้นเขตพื้นที่" && (
                        <div className="location">
                            <Typography className="header">ตำแหน่งของฉัน</Typography>
                            <Typography className="title" sx={{ textIndent: 12 }}>
                                โปรดกดปุ่ม"ตำแหน่งของฉัน"เพื่อระบุตำแหน่ง
                            </Typography>
                        </div>
                    )}

                    {searchParams.get("mapLayer") !== "ภาพถ่ายทางอากาศ" && (
                        <TargetTypeFilter handleFilterChange={handleFilterChange} />
                    )}

                    {searchParams.get("mapLayer") === "ภาพถ่ายทางอากาศ" && (
                        <FlyAreaFilter handleFilterChange={handleFilterChange} />
                    )}

                    {searchParams.get("mapLayer") === "เส้นทางบิน" && (
                        <DateRangeFilter handleFilterChange={handleFilterChange} />
                    )}
                </CardContent>
            </Collapse>
        </Card>
    )
}

export default MapControlPanel

const StatusFilter = ({ flightPlanStatusList, handleFilterChange }) => {
    return (
        <div className="flight-status">
            <Typography className="header">สถานะแผนบิน</Typography>
            {flightPlanStatusList.map(status => (
                <FormControlLabel 
                    key={status.value} 
                    control={<Checkbox />} 
                    label={status.label}
                    name="flightPlanStatus"
                    checked={status.checked}
                    value={status.value}
                    onChange={handleFilterChange}
                />
            ))}
        </div>
    )
}

const TargetTypeFilter = ({ handleFilterChange }) => {
    const { targetTypeList } = useMapFilters();

    return (
        <div className="target-type">
            <Typography className="header">ประเภทพื้นที่</Typography>
            {targetTypeList.map(targetType => (
                <FormControlLabel 
                    key={targetType.id} 
                    control={<Checkbox />} 
                    label={targetType.target_type_name}
                    name="targetType"
                    checked={targetType.checked}
                    value={targetType.id}
                    onChange={handleFilterChange}
                />
            ))}
        </div>
    )
}

const FlyAreaFilter = ({ handleFilterChange }) => {
    const { filters, flyingAreaList } = useMapFilters();

    return (
        <div className="flying-area">
            <Typography className="header">เลือกพื้นที่</Typography>
            <FormControl fullWidth size="small">
                <Select 
                    name="flyingAreaId"
                    value={filters.flyingAreaId} 
                    onChange={handleFilterChange}
                >
                    {flyingAreaList.map(flyingArea => 
                        <MenuItem 
                            key={flyingArea.id} 
                            value={flyingArea.id}
                        >
                            {flyingArea.target_area}
                        </MenuItem>
                    )}
                    <MenuItem value="">ทั้งหมด</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

const DateRangeFilter = ({ handleFilterChange }) => {
    const { filters } = useMapFilters();

    return (
        <div className="date-range">
            <Typography className="header">ช่วงเวลาของชุดข้อมูล</Typography>
            <Grid container gap={2} mt={2}>
                <Grid container alignItems="center">
                    <Grid item xs={2}>
                        <Typography className="header">เริ่ม :</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField 
                            fullWidth
                            type="datetime-local" 
                            label="ระบุวันที่และเวลาเริ่มต้น" 
                            InputLabelProps={{ shrink: true }} 
                            name="dateFrom"
                            value={filters.dateFrom}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="center">
                    <Grid item xs={2}>
                        <Typography className="header">จบ :</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField 
                            fullWidth
                            type="datetime-local" 
                            label="ระบุวันที่และเวลาสิ้นสุด" 
                            InputLabelProps={{ shrink: true }} 
                            name="dateTo"
                            value={filters.dateTo}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}