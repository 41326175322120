import { createContext, useState } from 'react'

import useFlyingArea from '../hooks/useFlyingArea';
import useTargetType from '../hooks/useTargetType';

export const MapFiltersContext = createContext();

const initFilters = {
    flightPlanStatus: [],
    dateFrom: "",
    dateTo: "",
    targetType: "",
    flyingAreaId: "",
}

const flightPlanStatus = [
    {
        label: 'ยังไม่ได้ดำเนินการ',
        value: 'notimplemented',
        checked: false,
    },
    {
        label: 'กำลังดำเนินการ',
        value: 'inprogress',
        checked: false,
    },
    {
        label: 'จบภารกิจ',
        value: 'complete',
        checked: false,
    },
]

function MapFiltersProvider({ children }) {

    const [filters, setFilters] = useState(initFilters);

    const [flightPlanStatusList, setFlightPlanStatusList] = useState(flightPlanStatus);
    const { targetTypeList, setTargetTypeList } = useTargetType({}, [], (value, setState) => {
        setState(value.map(targetType => {
            return {
                ...targetType,
                checked: false
            }
        }));
    });
    const { flyingAreaList, setFlyingAreaList } = useFlyingArea();

    const resetFilters = () => {
        setFilters(initFilters);
        setFlightPlanStatusList(prev => {
            return prev.map(status => {
                return {
                    ...status,
                    checked: false
                }
            })
        });
        setTargetTypeList(prev => {
            return prev.map(targetType => {
                return {
                    ...targetType,
                    checked: false
                }
            })
        });
    };

    return (
        <MapFiltersContext.Provider
            value={{
                filters, setFilters, resetFilters,
                flightPlanStatusList, setFlightPlanStatusList,
                targetTypeList, setTargetTypeList,
                flyingAreaList, setFlyingAreaList,
            }}
        >
            {children}
        </MapFiltersContext.Provider>
    )
}

export default MapFiltersProvider