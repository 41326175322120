import React, { useState, useRef } from "react";

import {
  Avatar,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import useAuth from "../../../hooks/useAuth";
import useErrorHandler from "../../../hooks/useErrorHandler";

import CameraAltIcon from "@mui/icons-material/CameraAlt";

import Swal from "sweetalert2";

import { userServices } from "../../../services/userServices";

const EditProfilePanel = ({ callback }) => {
  const { userData, setUserData, userNationalMaskedId } = useAuth();
  const { errorHandler } = useErrorHandler();

  const [localUserData, setLocalUserData] = useState({ ...userData });

  const [photo, setPhoto] = useState(userData.photo || {
    data: null,
    filename: "",
    originalname: "",
    imagePreview: "",
  });

  const fileInputRef = useRef(null);

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const decodedFileName = decodeURIComponent(selectedFile.name);
      setPhoto({
        data: selectedFile,
        filename: selectedFile.name,
        imagePreview: URL.createObjectURL(selectedFile),
      });
      console.log("Selected file:", decodedFileName);
    } else {
      console.log("No file selected");
    }
  };

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleClose = () => {
    callback();
  };

  const handleDataChange = ({ target }) => {
    setLocalUserData((prev) => {
      return {
        ...prev,
        [target.name]: target.value,
      };
    });
  };

  const handleSaveClick = (e) => {
    e.preventDefault();

    const data = {
      ...localUserData,
      oldFilename: userData.photo?.filename,
      photo: photo.data,
    };

    updateUser(data);
  };

  const updateUser = async (data) => {
    try {
      const res = await userServices.updateUser(data);
      console.log(res);
      if (res.status === 201) {
        const data = res.data.users;
        setUserData({
          ...data,
          photo: {
            ...photo,
            ...data.photo
          }
        })
        handleClose();
        Swal.fire({ title: "อัปเดตข้อมูลผู้ใช้สำเร็จ", icon: "success" });
      }
    } catch (error) {
      console.log(error);
      const { status, data } = error.response;
      errorHandler(
        'error: updateUser',
        status,
        data.message
      );
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <form onSubmit={handleSaveClick}>
        <Grid
          container
          justifyContent="center"
          gap={2}
          style={{
            width: "90%",
            height: "80%",
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "10px",
            padding: "30px",
            overflow: 'auto'
          }}
        >
          <Grid item xs={5} alignSelf="flex-start">
            <Typography
              sx={{ fontSize: "2vw", lineHeight: "2", fontWeight: "bold" }}
            >
              เลขประจำตัวประชาชน: {userNationalMaskedId}
            </Typography>

            {localUserData && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                  justifyContent: "center",
                  gap: "20px",
                  marginTop: "10%"
                }}
              >
                <Avatar
                  alt="Profile Picture"
                  src={photo?.imagePreview}
                  sx={{
                    width: "50%",
                    height: "auto",
                    aspectRatio: "1/1",
                    border: "2px solid gray",
                    borderRadius: "50%",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                />
                <div style={{ display: "flex", marginTop: '10%' }}>
                  <TextField
                    fullWidth
                    required
                    name="profile-image"
                    value={photo?.filename}
                    size="small"
                    placeholder="รูปภาพ "
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <CameraAltIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: "70%",
                      maxWidth: "400px",
                    }}
                  />
                  <div>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={handleFileSelect}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        marginLeft: "10px",
                        width: "30%",
                        maxWidth: "150px",
                        minWidth: "90px",
                        borderRadius: "30px",
                        backgroundColor: "#d5d5d5",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "lightgray", // สีเมื่อ Hover
                        },
                        "&:active": {
                          backgroundColor: "gray", // สีเมื่อคลิก
                        },
                      }}
                      onClick={handleFileUploadClick}
                    >
                      เลือกไฟล์
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    height: "9%",
                    maxWidth: "400px",
                    maxHeight: "55px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      width: "50%",
                      marginRight: "10px",
                      borderRadius: "30px",
                      backgroundColor: "#004aad",
                    }}
                  >
                    บันทึก
                  </Button>

                  <Button
                    variant="contained"
                    onClick={handleClose}
                    sx={{
                      width: "50%",
                      marginLeft: "10px",
                      borderRadius: "30px",
                      backgroundColor: "#fa4137",
                      "&:hover": {
                        backgroundColor: "#ff5a4e",
                      },
                      "&:active": {
                        backgroundColor: "#e6362d",
                      },
                    }}
                  >
                    ยกเลิก
                  </Button>
                </div>
              </div>
            )}
          </Grid>

          <Grid container item xs={5} direction="row">
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    lineHeight: "2",
                    textAlign: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  แก้ไขข้อมูลผู้ใช้งาน
                </Typography>
              </Grid>

              <Typography sx={{ fontSize: "1rem", lineHeight: "2" }}>
                Email
              </Typography>
              <TextField
                fullWidth
                required
                placeholder="admin.0000@mail.com"
                name="email"
                type="email"
                value={localUserData.email}
                onChange={handleDataChange}
                size="small"
              />
              <Typography sx={{ fontSize: "1rem", lineHeight: "2" }}>
                เบอร์ติดต่อ
              </Typography>
              <TextField
                fullWidth
                required
                name="tel_info"
                placeholder="เบอร์ติดต่อ"
                value={localUserData.tel_info}
                onChange={handleDataChange}
                size="small"
              />
              <Typography sx={{ fontSize: "1rem", lineHeight: "2" }}>
                ชื่อ-นามสกุล
              </Typography>

              <Grid container item xs={12}>
                <Grid item xs={6} sx={{ paddingRight: "10px" }}>
                  <TextField
                    fullWidth
                    required
                    name="first_name"
                    placeholder="ชื่อ"
                    value={localUserData.first_name}
                    onChange={handleDataChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6} sx={{ paddingLeft: "10px" }}>
                  <TextField
                    fullWidth
                    required
                    name="last_name"
                    placeholder="นามสกุล"
                    value={localUserData.last_name}
                    onChange={handleDataChange}
                    size="small"
                  />
                </Grid>
              </Grid>

              <Typography sx={{ fontSize: "1rem", lineHeight: "2" }}>
                สำนักงาน
              </Typography>

              <TextField
                fullWidth
                required
                name="office_unit"
                placeholder="สำนักงาน"
                value={localUserData.office_unit}
                onChange={handleDataChange}
                size="small"
              />

              <Typography sx={{ fontSize: "1rem", lineHeight: "2" }}>
                หน่วยงาน
              </Typography>

              <TextField
                fullWidth
                required
                name="institute"
                placeholder="หน่วยงาน"
                value={localUserData.institute}
                onChange={handleDataChange}
                size="small"
              />

              <Typography sx={{ fontSize: "1rem", lineHeight: "2" }}>
                ตำแหน่ง
              </Typography>

              <TextField
                fullWidth
                required
                name="position"
                placeholder="ตำแหน่ง"
                value={localUserData.position}
                onChange={handleDataChange}
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default EditProfilePanel;
