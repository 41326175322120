import React, { useCallback, useEffect, useState } from 'react'

import { flightImageServices } from '../../../services/flightImageServices';

import { TileLayer, useMap } from 'react-leaflet';

import { access_token, username } from '../../../constants/mapConstants';

import useErrorHandler from '../../../hooks/useErrorHandler';
import useMapFilters from '../../../hooks/useMapFilters';

function FlightImageLayer() {
    const map = useMap();

    const [mapIdList, setMapIdList] = useState([]);

    const { errorHandler } = useErrorHandler();
    const { filters } = useMapFilters();

    const getAllFlightImage = useCallback(async (currentFilters) => {
        try {
            const res = await flightImageServices.getAllFlightImage({ ...currentFilters, checkStatus: 1 });
            console.log('getAllFlightImage => ', res);
            if (res.status === 200) {
                const center = res.data.center;
                const mapList = res.data.data;
                center.length && map.flyTo(center, 13)
                setMapIdList(mapList.map(data => data.id));
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: getAllFlightImage',
                status,
                data.message
            );
        }
    }, []);

    useEffect(() => {
        getAllFlightImage(filters);
    }, [filters]);

    return (
        <div>
            {mapIdList.map(mapId => (
                <TileLayer
                    key={mapId}
                    url={`https://api.mapbox.com/v4/${username}.${mapId}/{z}/{x}/{y}.png?access_token=${access_token}`}
                />
            ))}
        </div>
    )
}

export default FlightImageLayer