import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './ManageFlightAreaForm.css'

import {
    Autocomplete, Box, FormControl,
    Grid, IconButton, InputLabel,
    MenuItem, Select, Tab,
    Tabs, TextField, Typography
} from '@mui/material'
import { Add, Remove } from '@mui/icons-material'

import OptionButton from '../../components/OptionButton/OptionButton';

import { useNavigate, useParams } from 'react-router-dom';

import { flyingAreaServices } from '../../services/flyingAreaServices';

import ProvinceProvider from '../../contexts/ProvinceListContext'

import useAreaBoundary from '../../hooks/useAreaBoundary';
import useErrorHandler from '../../hooks/useErrorHandler';
import useProvince from '../../hooks/useProvince'
import useTargetType from '../../hooks/useTargetType';

const initFlyingBase = {
    baseCode: '',
    utmZone: '',
    utmX: '',
    utmY: '',
    province: '',
    district: '',
    subDistrict: '',
}

function ManageFlightAreaForm() {
    const { id } = useParams();
    const navigate = useNavigate();

    const { errorHandler } = useErrorHandler();

    const [flyingArea, setFlyingArea] = useState({
        targetArea: '',
        targetTypeName: '',
        flyingBases: [],
    });

    const { areaBoundaryList } = useAreaBoundary();
    const { targetTypeList } = useTargetType();

    const [tabIndex, setTabIndex] = useState(0);
    const [openDropdown, setOpenDropdown] = useState(false);

    const getFlyingAreaById = useCallback(async (id) => {
        try {
            const res = await flyingAreaServices.getFlyingAreaById(id);
            console.log(res);
            if (res.status === 200) {
                const data = res.data.data;
                setFlyingArea(prev => {
                    return {
                        ...prev,
                        targetArea: data.id,
                        targetTypeName: data.TargetType?.target_type_name || "",
                        flyingBases: data.FlyingBases.map(flyingBase => {
                            return {
                                id: flyingBase.id,
                                baseCode: flyingBase.base_code,
                                utmZone: flyingBase.utm_zone,
                                utmX: flyingBase.utm_x,
                                utmY: flyingBase.utm_y,
                                province: flyingBase.province,
                                district: flyingBase.district,
                                subDistrict: flyingBase.sub_district,
                            }
                        }),
                    }
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: getFlyingAreaById',
                status,
                data.message,
                false,
                goToDashboard
            );
        }
    }, []);

    useEffect(() => {
        id && getFlyingAreaById(id);
    }, [id]);

    const handleTabChange = (event, newValue) => setTabIndex(newValue);

    const handleOpenDropdown = () => {
        setOpenDropdown(!openDropdown);
        setFlyingArea(prev => {
            return {
                ...prev,
                targetTypeName: '',
            }
        })
    }

    const handleFlyingAreaChange = ({ target }) => {
        setFlyingArea(prev => {
            return {
                ...prev,
                [target.name]: target.value,
            }
        });

        if (target.name === "targetArea") {
            getFlyingAreaById(target.value);
        }
    }

    const handleSaveClick = (e) => {
        e.preventDefault();
        const data = {
            flyingArea: {
                id: flyingArea.targetArea,
                target_type_name: flyingArea.targetTypeName,
            },
            flyingBases: flyingArea.flyingBases.map(flyingBase => {
                return {
                    id: flyingBase.id,
                    base_code: flyingBase.baseCode,
                    utm_zone: flyingBase.utmZone,
                    utm_x: flyingBase.utmX,
                    utm_y: flyingBase.utmY,
                    province: flyingBase.province,
                    district: flyingBase.district,
                    sub_district: flyingBase.subDistrict,
                }
            }),
        }
        console.log(data);

        updateFlyingArea(data);
    }

    const goToDashboard = () => navigate('/dashboard/manage-flight-area');

    const updateFlyingArea = async (data) => {
        try {
            const res = await flyingAreaServices.updateFlyingArea(data);
            console.log(res);
            if (res.status === 201) {
                console.log('area updated');
                goToDashboard();
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: updateFlyingArea',
                status,
                data.message
            );
        }
    }

    const addNewFlyingBase = () => {
        setFlyingArea(prev => {
            return {
                ...prev,
                flyingBases: [...prev.flyingBases, { ...initFlyingBase }]
            }
        });
    }

    return (
        <form onSubmit={handleSaveClick}>
            <Grid container className="manage-flight-area-form">
                <Typography>ข้อมูลพื้นฐาน</Typography>
                <Grid container item xs={12}>
                    <Grid item xs={6} padding={3}>
                        <FormControl fullWidth>
                            <InputLabel id="target-area">พื้นที่เป้าหมาย</InputLabel>
                            <Select
                                required
                                labelId="target-area"
                                label="พื้นที่เป้าหมาย"
                                name="targetArea"
                                value={flyingArea.targetArea}
                                onChange={handleFlyingAreaChange}
                            >
                                {areaBoundaryList.map(areaBoundary =>
                                    <MenuItem
                                        key={areaBoundary.id}
                                        value={areaBoundary.id}
                                        disabled={!areaBoundary.table_status}
                                    >
                                        {areaBoundary.target_area}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} padding={3}>
                        <div style={{ display: 'flex' }}>
                            {
                                openDropdown ? (
                                    <TextField
                                        fullWidth
                                        required
                                        name="targetTypeName"
                                        value={flyingArea.targetTypeName}
                                        onChange={handleFlyingAreaChange}
                                        placeholder="ประเภทเป้าหมาย"
                                    />
                                ) : (
                                    <FormControl fullWidth>
                                        <InputLabel id="target-type">ประเภทเป้าหมาย</InputLabel>
                                        <Select
                                            required
                                            labelId="target-type"
                                            label="ประเภทเป้าหมาย"
                                            name="targetTypeName"
                                            value={flyingArea.targetTypeName}
                                            onChange={handleFlyingAreaChange}
                                        >
                                            {targetTypeList.map(targetType =>
                                                <MenuItem
                                                    key={targetType.id}
                                                    value={targetType.target_type_name}
                                                    disabled={!targetType.table_status}
                                                >
                                                    {targetType.target_type_name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                )
                            }
                            <IconButton onClick={handleOpenDropdown}>
                                {openDropdown ? <Remove /> : <Add />}
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>

                <Grid container item xs={12} padding={3} gap={2}>
                    <Grid item xs={12} display="flex" alignItems="center">
                        <OptionButton
                            bgColor="var(--options-btn-bg-color-1)"
                            hoverColor="var(--options-btn-bg-hover-1)"
                            onClick={addNewFlyingBase}
                        >
                            <Add />เพิ่มฐานบิน
                        </OptionButton>
                        <Box sx={{ maxWidth: 500 }}>
                            <Tabs
                                variant="scrollable"
                                scrollButtons="auto"
                                value={tabIndex}
                                onChange={handleTabChange}
                            >
                                {flyingArea.flyingBases.map(
                                    (flyingBase, index) =>
                                        <Tab
                                            key={flyingBase.id || index}
                                            sx={{ fontWeight: 'bold' }}
                                            label={index + 1}
                                        />
                                )}
                            </Tabs>
                        </Box>
                    </Grid>

                    {flyingArea.flyingBases.map(
                        (flyingBase, index) => (
                            <TabPanel
                                key={flyingBase.id || index}
                                tabIndex={tabIndex}
                                index={index}
                                flyingBase={flyingBase}
                                setFlyingArea={setFlyingArea}
                            />
                        )
                    )}

                    <Grid item xs={12}>
                        <div className="btn">
                            <OptionButton
                                text="Save"
                                type="submit"
                                bgColor="var(--options-btn-bg-color-5)"
                                hoverColor="var(--options-btn-bg-hover-5)"
                            />
                            <OptionButton
                                text="Cancel"
                                bgColor="var(--options-btn-bg-color-2)"
                                hoverColor="var(--options-btn-bg-hover-2)"
                                textColor="gray"
                                onClick={goToDashboard}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default withProvinceProvider(ManageFlightAreaForm)

function withProvinceProvider(WrappedComponent) {
    return (props) => (
        <ProvinceProvider>
            <WrappedComponent {...props} />
        </ProvinceProvider>
    );
}

const FlyingBaseForm = ({ index, flyingBase: currentflyingBase, setFlyingArea }) => {

    const { provinces } = useProvince();

    const [selectedProvince, setSelectedProvince] = useState(currentflyingBase.province || "");
    const [selectedDistrict, setSelectedDistrict] = useState(currentflyingBase.district || "");

    const districts = useMemo(() => {
        const province = provinces.find(
            (province) => province.name_th === (selectedProvince?.name_th ?? selectedProvince)
        );
        return province?.amphure || [];
    }, [provinces, selectedProvince]);

    const subDistricts = useMemo(() => {
        const district = districts.find(
            (district) => district.name_th === (selectedDistrict?.name_th ?? selectedDistrict)
        );
        return district?.tambon || [];
    }, [districts, selectedDistrict]);

    const handleChange = ({ target }) => {
        setFlyingArea(prev => {
            const flyingBase = prev.flyingBases[index];
            const newFlyingBase = {
                ...flyingBase,
                [target.name]: target.value,
            }

            prev.flyingBases[index] = newFlyingBase;

            return {
                ...prev,
                flyingBases: [...prev.flyingBases],
            }
        })
    }

    return (
        <Grid container item xs={12} gap={2}>
            <Grid item xs={12} display="flex" alignItems="center" gap={2}>
                <TextField
                    fullWidth
                    name="baseCode"
                    value={currentflyingBase.baseCode}
                    onChange={handleChange}
                    size="small"
                    placeholder="เลขที่ฐานบิน"
                />
                <TextField
                    fullWidth
                    name="utmZone"
                    value={currentflyingBase.utmZone}
                    onChange={handleChange}
                    size="small"
                    placeholder="โซน UTM ฐานบิน"
                />
                <TextField
                    fullWidth
                    name="utmX"
                    value={currentflyingBase.utmX}
                    onChange={handleChange}
                    size="small"
                    placeholder="พิกัด UTM ฐานบิน X"
                />
                <TextField
                    fullWidth
                    name="utmY"
                    value={currentflyingBase.utmY}
                    onChange={handleChange}
                    size="small"
                    placeholder="พิกัด UTM ฐานบิน Y"
                />
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center" gap={2}>
                <Autocomplete
                    fullWidth
                    freeSolo
                    disablePortal
                    size="small"
                    options={provinces}
                    getOptionLabel={(option => option?.name_th ?? option)}
                    value={currentflyingBase.province}
                    onChange={(event, newValue) => {
                        setSelectedProvince(newValue);
                        handleChange({ target: { name: "province", value: newValue?.name_th || "" } });
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="จังหวัด" />}
                />
                <Autocomplete
                    fullWidth
                    freeSolo
                    disablePortal
                    size="small"
                    options={districts}
                    getOptionLabel={(option => option?.name_th ?? option)}
                    value={currentflyingBase.district}
                    onChange={(event, newValue) => {
                        console.log(newValue)
                        setSelectedDistrict(newValue);
                        handleChange({ target: { name: "district", value: newValue?.name_th || "" } });
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="อำเภอ" />}
                />
                <Autocomplete
                    fullWidth
                    freeSolo
                    disablePortal
                    size="small"
                    options={subDistricts}
                    getOptionLabel={(option => option?.name_th ?? option)}
                    value={currentflyingBase.subDistrict}
                    onChange={(event, newValue) => {
                        console.log(newValue)
                        handleChange({ target: { name: "subDistrict", value: newValue?.name_th || "" } });
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="ตำบล" />}
                />
            </Grid>
        </Grid>
    )
}

const TabPanel = (props) => {
    const { flyingBase, setFlyingArea, tabIndex, index } = props;

    return tabIndex === index && (
        <FlyingBaseForm index={index} flyingBase={flyingBase} setFlyingArea={setFlyingArea} />
    );
}