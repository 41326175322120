import React, { useEffect, useState, useRef } from "react";
import "./ManageUserForm.css";

import {
  Avatar,
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import CameraAltIcon from "@mui/icons-material/CameraAlt";

import { useNavigate, useParams } from "react-router-dom";
import { userServices } from "../../services/userServices";

import OptionButton from "../../components/OptionButton/OptionButton";

import { base64ImageToFile } from "../../utils";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import Swal from "sweetalert2";

import useAuth from "../../hooks/useAuth";
import useErrorHandler from "../../hooks/useErrorHandler";
import useUserGroup from "../../hooks/useUserGroup";

function ManageUserForm() {
  const { userData, fetchUserData } = useAuth();
  const { errorHandler } = useErrorHandler();

  const { id } = useParams();

  const navigate = useNavigate();

  const [photo, setPhoto] = useState({
    filename: "",
  });

  const [currentUserData, setCurrentUserData] = useState({
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    contactInfo: "",
    office_unit: "",
    tel_info: "",
    institute: "",
    position: "",
    group: {
      group_name: "ชื่อกลุ่ม",
    },
  });

  const { userGroupList: userGroup } = useUserGroup();

  const fileInputRef = useRef(null);

  const [changePassword, setChangePassword] = useState(id ? false : true);

  const openPasswordField = () => {
    setChangePassword(true);
  };

  const closePasswordField = () => {
    setChangePassword(false);
  };

  const [passwordData, setPasswordData] = useState({
    password: "",
    confirm_password: "",
  });

  const handlePasswordChange = ({ target }) => {
    console.log("target", target.name, "val", target.value);
    setPasswordData((prev) => {
      return {
        ...prev,
        [target.name]: target.value,
      };
    });
  };

  useEffect(() => {
    const getUserById = async () => {
      try {
        const res = await userServices.getUserById(id);
        console.log(res);
        if (res.status === 200) {
          const data = res.data.data;
          if (data.photo) {
            setPhoto({
              ...data.photo,
              data: data.photo
                ? base64ImageToFile(
                  data.photo.imagePreview,
                  data.photo.originalname
                )
                : null,
            });
          }
          setCurrentUserData({ ...data });
        }
      } catch (error) {
        console.log(error);
        const { status, data } = error.response;
        errorHandler(
          'error: getUserById',
          status,
          data.message,
          false,
          goToDashboard
        );
      }
    };

    id && getUserById();
  }, [id]);

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const decodedFileName = decodeURIComponent(selectedFile.name);
      setPhoto({
        data: selectedFile,
        filename: selectedFile.name,
        imagePreview: URL.createObjectURL(selectedFile),
      });
      console.log("Selected file:", decodedFileName);
    } else {
      console.log("No file selected");
    }
  };

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleDataChange = ({ target }) => {
    let newGroup = currentUserData.group;
    if (target.name === "group") {
      newGroup = userGroup.find((group) => group.group_name === target.value);
    }

    setCurrentUserData((prev) => {
      return {
        ...prev,
        groupId: newGroup.id,
        [target.name]: target.value,
        group: newGroup,
      };
    });
  };

  const handleSaveClick = (e) => {
    e.preventDefault();

    const data = {
      ...currentUserData,
      oldFilename: currentUserData?.photo?.filename,
      photo: photo.data,
    };

    if (changePassword) {
      if (passwordData.password !== passwordData.confirm_password) {
        Swal.fire({
          title: "พบข้อผิดพลาด",
          text: "รหัสผ่านไม่ตรงกัน",
          icon: "error",
        });
        return;
      } else {
        data.password = passwordData.password;
      }
    }

    if (!id) {
      createUser(data);
    } else {
      updateUser(data);
    }
  };

  const goToDashboard = () => navigate("/dashboard/manage-user");

  const createUser = async (data) => {
    try {
      const res = await userServices.createUser(data);
      console.log(res);
      if (res.status === 201) {
        console.log("user created");
        goToDashboard();
      }
    } catch (error) {
      console.log(error);
      const { status, data } = error.response;
      errorHandler(
        'error: createUser',
        status,
        data.message
      );
    }
  };

  const updateUser = async (data) => {
    try {
      // data.photos = {};
      const res = await userServices.updateUser({ id, ...data });
      console.log(res);
      if (res.status === 201) {
        console.log("user updated");
        if (currentUserData.id === userData.id) {
          fetchUserData(); //กรณีอัพข้อมูลตัวเอง มันไมไ่ด้ fetch ข้อมูลใหม่
        }
        goToDashboard();
      }
    } catch (error) {
      console.log(error);
      const { status, data } = error.response;
      errorHandler(
        'error: updateUser',
        status,
        data.message
      );
    }
  };

  return (
    <form onSubmit={handleSaveClick}>
      <Grid container direction="row" className="manage-user-form">
        <Grid container item xs={6} gap={1}>
          <GridForm>
            <Typography className="title">เลขประจำตัวประชาชน</Typography>
            <TextField
              fullWidth
              required
              name="username"
              placeholder="เลขประจำตัวประชาชน (จำกัด 13 หลัก)"
              value={currentUserData.username}
              onChange={handleDataChange}
              size="small"
              inputProps={{
                maxLength: 13,
                inputMode: "numeric",
              }}
            />
          </GridForm>
          <GridForm>
            <Typography className="title">E-mail</Typography>
            <TextField
              fullWidth
              required
              name="email"
              placeholder="admin.0000@mail.com"
              value={currentUserData.email}
              onChange={handleDataChange}
              size="small"
            />
          </GridForm>
          <GridForm>
            <Typography className="title">เบอร์ติดต่อ</Typography>
            <TextField
              fullWidth
              required
              name="tel_info"
              placeholder="เบอร์ติดต่อ"
              value={currentUserData.tel_info}
              onChange={handleDataChange}
              size="small"
            />
          </GridForm>
          <GridForm>
            <Typography className="title">ชื่อ-นามสกุล</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  placeholder="ชื่อ"
                  name="first_name"
                  value={currentUserData.first_name}
                  onChange={handleDataChange}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  placeholder="นามสกุล"
                  name="last_name"
                  value={currentUserData.last_name}
                  onChange={handleDataChange}
                  size="small"
                />
              </Grid>
            </Grid>
          </GridForm>
          <GridForm>
            <Typography className="title">สำนักงาน</Typography>
            <TextField
              fullWidth
              required
              name="office_unit"
              placeholder="สำนักงาน"
              value={currentUserData.office_unit}
              onChange={handleDataChange}
              size="small"
            />
          </GridForm>
          <GridForm>
            <Typography className="title">หน่วยงาน</Typography>
            <TextField
              fullWidth
              required
              name="institute"
              placeholder="หน่วยงาน"
              value={currentUserData.institute}
              onChange={handleDataChange}
              size="small"
            />
          </GridForm>
          <GridForm>
            <Typography className="title">ตำแหน่ง</Typography>
            <TextField
              fullWidth
              required
              name="position"
              placeholder="ตำแหน่ง"
              value={currentUserData.position}
              onChange={handleDataChange}
              size="small"
            />
          </GridForm>
          <Grid item xs={6}></Grid>

          <Grid item xs={12}>
            <FormLabel className="title">กลุ่มผู้ใช้งาน</FormLabel>
            <RadioGroup
              value={currentUserData.group && currentUserData.group.group_name}
              name="group"
              onChange={handleDataChange}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              {userGroup &&
                userGroup.map((group) => {
                  const value = group.group_name;
                  const label = group.group_name;
                  const role = group.role;
                  return (
                    <FormControlLabel
                      name="group"
                      key={group.id}
                      value={value}
                      control={<Radio />}
                      label={label}
                      disabled={
                        userData?.group.role !== "SUPER_ADMIN" &&
                        role === "SUPER_ADMIN"
                      } // กรณีถ้า ไม่ใช่ super admin จะเลือก role ที่เป็น super ให้คนอื่นไม่ได้
                    />
                  );
                })}
            </RadioGroup>
          </Grid>
          <Grid item xs={12} className="btn">
            <OptionButton
              text="Save"
              type="submit"
              bgColor="var(--options-btn-bg-color-5)"
              hoverColor="var(--options-btn-bg-hover-5)"
            />
            <OptionButton
              text="Cancel"
              bgColor="var(--options-btn-bg-color-2)"
              hoverColor="var(--options-btn-bg-hover-2)"
              textColor="gray"
              onClick={goToDashboard}
            />
          </Grid>
        </Grid>

        <Grid container item xs={6} gap={1} sx={{ marginTop: "5%" }}>
          <Grid item xs={12}>
            <Avatar
              alt="Profile Picture"
              src={photo?.imagePreview}
              sx={{
                width: "50%",
                height: "auto",
                aspectRatio: "1/1",
                border: "2px solid gray",
                borderRadius: "50%",
                margin: "auto",
              }}
            />
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                justifyContent: "center",
              }}
            >
              <TextField
                fullWidth
                required
                name="profile-image"
                value={photo?.filename}
                size="small"
                placeholder="เลือกรูป"
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <CameraAltIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: "70%",
                  maxWidth: "400px",
                }}
              />
              <div>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                />
                <Button
                  variant="contained"
                  sx={{
                    marginLeft: "10px",
                    width: "30%",
                    maxWidth: "150px",
                    minWidth: "90px",
                    backgroundColor: "#999999",
                    color: "#000000",
                    opacity: 0.7,
                  }}
                  onClick={handleFileUploadClick}
                >
                  เลือกไฟล์
                </Button>
              </div>
            </div>
            <Grid item xs={12} sx={{ textAlign: "center", paddingTop: "10%" }}>
              {changePassword ? (
                <PasswordFields
                  handlePasswordChange={handlePasswordChange}
                  closePasswordField={closePasswordField}
                  isCancancel={id ? true : false}
                />
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "50px",
                    width: "60%",
                    maxWidth: "250px",
                    backgroundColor: "#004aad",
                  }}
                  onClick={openPasswordField}
                >
                  เปลี่ยนรหัสผ่าน
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default ManageUserForm;

const GridForm = ({ children }) => {
  return (
    <Grid
      container
      item
      xs={10}
      gap={1}
      sx={{ bgcolor: "#FAFAFA", borderRadius: 3, p: 3 }}
    >
      {children}
    </Grid>
  );
};

const PasswordFields = ({
  handlePasswordChange,
  closePasswordField,
  isCancancel,
}) => {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleToggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  return (
    <div>
      <Typography className="title" sx={{ lineHeight: "2", }}>
        รหัสผ่าน
      </Typography>
      <TextField
        fullWidth
        required
        name="password"
        type={showNewPassword ? "text" : "password"}
        placeholder="*********"
        onChange={handlePasswordChange}
        size="small"
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={handleToggleNewPassword}
              edge="end"
              style={{ marginRight: "8px" }}
            >
              {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          ),
        }}
      />
      <Typography className="title" sx={{ lineHeight: "2", marginTop: '10px' }} >
        ยืนยันรหัสผ่าน
      </Typography>
      <TextField
        fullWidth
        required
        name="confirm_password"
        type={showConfirmPassword ? "text" : "password"}
        placeholder="*********"
        onChange={handlePasswordChange}
        size="small"
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={handleToggleConfirmPassword}
              edge="end"
              style={{ marginRight: "8px" }}
            >
              {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          ),
        }}
      />
      {isCancancel && (
        <Button
          variant="contained"
          sx={{
            borderRadius: "50px",
            width: "60%",
            maxWidth: "250px",
            backgroundColor: "#004aad",
            marginTop: "20px",
          }}
          onClick={closePasswordField}
        >
          ยกเลิก
        </Button>
      )}
    </div>
  );
};
