import React, { useEffect, useMemo, useState } from 'react'
import './ManageFlightImageForm.css'

import { Badge, Box, FormControl, Grid, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AccessTimeFilled, Add, BackupOutlined, Cancel, CheckCircle, Delete, Edit, FileDownload, Save } from '@mui/icons-material';

import { flightImageServices } from '../../../services/flightImageServices';

import Swal from 'sweetalert2';

import OptionButton from '../../../components/OptionButton/OptionButton';

import { useParams } from 'react-router-dom';

import useErrorHandler from '../../../hooks/useErrorHandler';
import useFile from '../../../hooks/useFile';
import useFlyingArea from '../../../hooks/useFlyingArea';

const initFile = {
    data: null,
    originalname: "",
}

const uploadStatusList = {
    pending: {
        text: 'กำลังประมวลผล',
        icon: <AccessTimeFilled color='info' />
    },
    completed: {
        text: 'อัปโหลดสำเร็จ',
        icon: <CheckCircle color='success' />
    },
    failed: {
        text: 'อัปโหลดไม่สำเร็จ',
        icon: <Cancel color='error' />
    },
}

const COMPLETE = 100;

function ManageFlightImageForm() {
    const { id } = useParams();
    const [fileList, setFileList] = useState([]);
    const [flyingAreaId, setFlyingAreaId] = useState("");

    const { errorHandler } = useErrorHandler();
    const { flyingAreaList } = useFlyingArea();

    useEffect(() => {
        const getAllFlightImageByFlyingArea = async () => {
            try {
                const res = await flightImageServices.getAllFlightImageByFlyingArea(id);
                console.log("getAllFlightImageByFlyingArea => ", res);
                if (res.status === 200) {
                    const data = res.data.data;
                    if (data.length) {
                        setFlyingAreaId(data[0].FlyingAreaId);
                    }
                    setFileList(data.map(file => {
                        return {
                            ...file,
                            file: file.area_boundary_files && {
                                ...file.area_boundary_files,
                                data: true,
                            }
                        }
                    }));
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getAllFlightImageByFlyingArea',
                    status,
                    data.message
                );
            }
        }

        id && getAllFlightImageByFlyingArea();
    }, [id]);

    const handleFlyingAreaChange = ({ target }) => setFlyingAreaId(target.value);

    const handleAddClick = () => {
        setFileList(prev => {
            return [...prev, {}]
        });
    }

    return (
        <Box className="flight-image-form" p={3}>
            <Typography className="title">ภาพถ่ายทางอากาศ</Typography>

            <Grid container mt={4}>
                <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 700 }}>
                        พื้นที่เป้าหมาย
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth size="small">
                        <Select
                            required
                            name="flyingAreaId"
                            value={flyingAreaId}
                            onChange={handleFlyingAreaChange}
                            inputProps={{
                                form: "flight-image-upload"
                            }}
                        >
                            {flyingAreaList.map(flyingArea =>
                                <MenuItem
                                    key={flyingArea.id}
                                    value={flyingArea.id}
                                    disabled={!flyingArea.table_status}
                                >
                                    {flyingArea.target_area}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {fileList.map((file, index) => (
                <UploadForm
                    key={file.id || `${index}temp`}
                    index={index}
                    file={file}
                    setFileList={setFileList}
                    flyingAreaId={flyingAreaId}
                />
            ))}

            <OptionButton
                text="เพิ่ม"
                bgColor="var(--options-btn-bg-color-1)"
                hoverColor="var(--options-btn-bg-hover-1)"
                onClick={handleAddClick}
                startIcon={<Add />}
            />
        </Box>
    )
}

export default ManageFlightImageForm

const UploadForm = ({ index, file, setFileList, flyingAreaId }) => {
    const { errorHandler } = useErrorHandler();

    const [uploadedFile, setUploadedFile] = useState(file.file || initFile);
    const [filename, setFilename] = useState(file.file_name || "");

    const [isEdit, setEdit] = useState(false);
    const [isFileChange, setIsFileChange] = useState(false);
    const [loading, setLoading] = useState(false);
    const [percent, setPercent] = useState(0);

    const Toast = useMemo(() => {
        return Swal.mixin({
            toast: true,
            position: 'bottom-start',
            showConfirmButton: false,
        });
    }, []);

    const handleFilenameChange = ({ target }) => setFilename(target.value);

    const handleEditClick = () => setEdit(true);

    const handleDeleteClick = async () => {
        Swal.fire({
            title: `คุณต้องการลบข้อมูลหรือไม่?`,
            text: 'เมื่อยืนยันแล้วจะไม่สามารถกู้คืนได้',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'var(--options-btn-bg-color-5)',
            confirmButtonText: 'ยืนยันการลบ',
            cancelButtonColor: 'var(--options-btn-bg-color-6)',
            cancelButtonText: 'ยกเลิกการลบ',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: `กำลังลบข้อมูล`,
                    allowOutsideClick: false,
                    didOpen: async () => {
                        Swal.showLoading();
                        await deleteFlightImageById();
                    }
                });
            }
        });
    };

    const deleteFlightImageById = async () => {
        try {
            const res = await flightImageServices.deleteFlightImageById(file.id);
            console.log('deleteFlightImageById => ', res);
            if (res.status === 200) {
                Swal.fire({
                    title: "ลบข้อมูลสำเร็จ",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setFileList(prev => {
                            return prev.filter(currentFile => currentFile.id !== file.id)
                        });
                    }
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: deleteFlightImageById',
                status,
                data.message
            );
        }
    }

    const handleSaveClick = (e) => {
        e.preventDefault();
        const data = {
            flightImage: {
                flyingAreaId,
                file_name: filename,
                hasNewFile: isFileChange,
            },
            file: uploadedFile?.data,
        }
        console.log(data);

        if (!isEdit) {
            runSwal('สร้างภาพถ่าย', () => createFlightImage(data));
        } else {
            data.flightImage.id = file.id;
            runSwal('อัปเดตภาพถ่าย', () => updateFlightImage(data));
        }
    }

    const runSwal = (title, service) => {
        Swal.fire({
            title: `ยืนยันการ${title}`,
            showCancelButton: true,
            confirmButtonColor: 'var(--options-btn-bg-color-5)',
            confirmButtonText: 'ยืนยัน',
            cancelButtonColor: 'var(--options-btn-bg-color-6)',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                await service();
            }
        })
    }

    const createFlightImage = async (data) => {
        try {
            const res = await flightImageServices.createFlightImage(data, setPercent);
            console.log("createFlightImage => ", res);
            if (res.status === 201) {
                const resData = res.data;
                setFileList(prev => {
                    prev[index] = {
                        id: resData.id,
                        file_name: filename,
                        upload_status: resData.upload_status,
                        file: {
                            ...resData.area_boundary_files,
                            data: true,
                        },
                    }

                    return [...prev]
                });
                setIsFileChange(false);
                Toast.fire({
                    title: 'อัปโหลดไฟล์สำเร็จ',
                    icon: 'success',
                    timer: 3000,
                    timerProgressBar: true,
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createFlightImage',
                status,
                data.message,
                true
            );
        } finally {
            setLoading(false);
        }
    }

    const updateFlightImage = async (data) => {
        try {
            const res = await flightImageServices.updateFlightImage(data, setPercent);
            console.log("updateFlightImage => ", res);
            if (res.status === 201) {
                const resData = res.data;
                setFileList(prev => {
                    prev[index] = {
                        filename,
                        id: resData.id,
                        upload_status: resData.upload_status,
                        file: {
                            ...resData.area_boundary_files,
                            data: true,
                        },
                    }

                    return [...prev]
                });
                setEdit(false);
                setIsFileChange(false);
                Toast.fire({
                    title: 'อัปโหลดไฟล์สำเร็จ',
                    icon: 'success',
                    timer: 3000,
                    timerProgressBar: true,
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: updateFlightImage',
                status,
                data.message,
                true
            );
        } finally {
            setLoading(false);
        }
    }

    return (
        <form id="flight-image-upload" onSubmit={handleSaveClick} >
            <Grid container className="flight-image-upload">
                <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 700 }}>
                        ชื่อไฟล์
                    </Typography>
                </Grid>

                <Grid container item xs={12} gap={1}>
                    <Grid item xs={4}>
                        <TextField
                            required
                            fullWidth
                            size="small"
                            placeholder='(ไม่ซ้ำกัน)'
                            value={filename}
                            onChange={handleFilenameChange}
                            InputProps={{
                                readOnly: !(!file.file || isEdit),
                            }}
                        />
                    </Grid>
                    <Grid container item xs={7} gap={1}>
                        {
                            (!file.file || isEdit) ? (
                                <UploadButton
                                    loading={loading}
                                    uploadedFile={uploadedFile}
                                    setUploadedFile={setUploadedFile}
                                    setIsFileChange={setIsFileChange}
                                />
                            ) : (
                                <DownloadButton file={file} />
                            )
                        }

                        {
                            (!file.file || isEdit) ? (
                                <LoadingButton
                                    variant="contained"
                                    type="submit"
                                    size="small"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<Save />}
                                    disabled={!uploadedFile.data}
                                    sx={{
                                        bgcolor: "var(--options-btn-bg-color-1)",
                                        '&:hover': { bgcolor: "var(--options-btn-bg-hover-1)" },
                                        fontWeight: 700,
                                        borderRadius: "12px",
                                    }}
                                >
                                    {loading ? (
                                        percent !== COMPLETE ? (
                                            <span>กำลังอัปโหลดไฟล์ ({percent}%)</span>
                                        ) : (
                                            <span>กำลังประมวลผลไฟล์</span>
                                        )
                                    ) : (
                                        <span>บันทึก</span>
                                    )}
                                </LoadingButton>
                            ) : (
                                <div className="btn">
                                    <IconButton onClick={handleEditClick}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton onClick={handleDeleteClick}>
                                        <Delete />
                                    </IconButton>
                                    <div className='map-status'>
                                        <Typography sx={{ fontWeight: 700 }}>
                                            สถานะแผนที่:
                                        </Typography>
                                        <IconButton disabled>
                                            {uploadStatusList[file.upload_status].icon}
                                        </IconButton>
                                        <Typography>
                                            {uploadStatusList[file.upload_status].text}
                                        </Typography>
                                    </div>
                                </div>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

const UploadButton = ({ uploadedFile, setUploadedFile, setIsFileChange }) => {

    const handleFileChange = ({ target }) => {
        const files = target.files;
        console.log(files);
        if (files.length === 0) return;

        const currentFile = files[0];

        setIsFileChange(true);
        setUploadedFile({
            data: currentFile,
            originalname: currentFile.name,
        });
    };

    return (
        <Badge variant="dot" color="primary" invisible={!uploadedFile.data}>
            <OptionButton
                input
                bgColor='var(--options-btn-bg-color-5)'
                hoverColor='var(--options-btn-bg-hover-5)'
                startIcon={<BackupOutlined />}
            >
                อัปโหลดไฟล์ชนิด .TIF {uploadedFile.data && " (1 ไฟล์)"}
                <input
                    hidden
                    type="file"
                    onChange={handleFileChange}
                    accept="image/tiff"
                />
            </OptionButton>
        </Badge>
    )
}

const DownloadButton = ({ file }) => {
    const { downloadFile } = useFile();

    const handleDownloadClick = async () => {
        const currentFile = file.file;
        console.log(currentFile);
        await downloadFile(currentFile);
    }

    return (
        <OptionButton
            text="ดาวน์โหลด"
            bgColor="var(--options-btn-bg-color-1)"
            hoverColor="var(--options-btn-bg-hover-1)"
            onClick={handleDownloadClick}
            startIcon={<FileDownload />}
        />
    )
}