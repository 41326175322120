import React from "react";

import {
    Button,
} from "@mui/material";

import DataTable from '../../components/DataTable/DataTable'

import EditButton from '../../components/TableButton/EditButton';

import { useNavigate } from 'react-router-dom';

import useDataTable from "../../hooks/useDataTable";
import useUserGroup from "../../hooks/useUserGroup";

const columns = [
    {
        field: 'row_num',
        headerName: 'ลำดับ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
    },
    {
        field: 'group_name',
        headerName: 'กลุ่มผู้ใช้งาน',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
    },
    {
        field: 'role',
        headerName: 'สิทธิ์',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
    },
    {
        field: 'edit',
        headerName: 'แก้ไข',
        type: 'actions',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 1,
        renderCell: (param) => <EditButton id={param.row.id} formURL="manage-group-form" /> //Change url
    },

]

function ManageUserGroup() {

    const navigate = useNavigate();

    const handleAddClick = () => navigate('/dashboard/manage-group-form');

    const { paginationModel } = useDataTable();

    const { userGroupList: rows, rowCount } = useUserGroup(
        { ...paginationModel },
        [paginationModel.page]
    );

    return (
        <div className="manage-user-group" style={{ display: 'flex', flexDirection: 'column' }}>

            <Button variant="contained" sx={{ marginLeft: '10px', width: '30%', maxWidth: '150px', minWidth: '90px', borderRadius: '10px' }} onClick={handleAddClick}>เพิ่ม</Button>
            <DataTable
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                pageProps={paginationModel}
            />
        </div>
    );
}

export default ManageUserGroup;
