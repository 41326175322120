import React, { useState } from 'react'
import './ManageDrone.css'

import { Box, Checkbox, FormControlLabel, Grid, InputAdornment, TextField, Typography } from '@mui/material'

import { useNavigate } from 'react-router-dom';

import DataTable from '../../components/DataTable/DataTable'
import OptionButton from '../../components/OptionButton/OptionButton';
import DeleteButton from '../../components/TableButton/DeleteButton';
import EditButton from '../../components/TableButton/EditButton';
import ExcelButton from '../../components/ExcelButton/ExcelButton';

import { droneServices } from '../../services/droneServices';

import useDataTable from '../../hooks/useDataTable';
import useDrone from '../../hooks/useDrone';
import useDroneSize from '../../hooks/useDroneSize';
import useDroneType from '../../hooks/useDroneType';

const columns = [
    {
        field: 'row_num',
        headerName: 'ลำดับ',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        sortable: false,
    },
    {
        field: 'drone_code',
        headerName: 'รหัส',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        sortable: false,
    },
    {
        field: 'droneType',
        headerName: 'ประเภทอากาศยาน',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 150,
        sortable: false,
        valueGetter: (param) => param.row.DroneType.type_name
    },
    {
        field: 'droneSize',
        headerName: 'ขนาดอากาศยาน',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 150,
        sortable: false,
        valueGetter: (param) => param.row.DroneSize.size_name
    },
    {
        field: 'equipped_payload',
        headerName: 'สัมภาระที่ติดตั้งได้',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 150,
        sortable: false,
    },
    {
        field: 'drone_status',
        headerName: 'สถานะ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (param) => <DroneStatus status={param.row.drone_status} />
    },
    {
        field: 'edit',
        headerName: 'แก้ไข',
        headerAlign: 'center',
        type: 'actions',
        sortable: false,
        renderCell: (param) => <EditButton id={param.row.drone_code} formURL="manage-drone-form" />
    },
    {
        field: 'delete',
        type: 'actions',
        sortable: false,
        renderCell: (param) => <DeleteButton id={param.row.id} service={droneServices.deleteDroneById} />
    },
];

const initFilters = {
    search: '',
    droneType: [],
    droneSize: [],
    dateFrom: '',
    dateTo: '',
}

const droneStatusList = {
    ready: {
        label: 'พร้อมใช้งาน',
        value: 'ready',
    },
    busy: {
        label: 'กำลังดำเนินการ',
        value: 'busy',
    },
    unavailable: {
        label: 'ไม่พร้อมใช้งาน',
        value: 'unavailable',
    }
}

function ManageDrone() {
    const navigate = useNavigate();

    const { paginationModel, resetPage } = useDataTable();

    const [filters, setFilters] = useState(initFilters);
    const [paginationfilters, setPaginationFilters] = useState(initFilters);
    const [droneTypeCheckedAll, setDroneTypeCheckedAll] = useState(false);
    const [droneSizeCheckedAll, setDroneSizeCheckedAll] = useState(false);
    const { droneTypeList, setDroneTypeList } = useDroneType({}, [], (value, setState) => {
        setState(value.map(droneType => {
            return {
                ...droneType,
                checked: false,
            }
        }));
    });
    const { droneSizeList, setDroneSizeList } = useDroneSize({}, [], (value, setState) => {
        setState(value.map(droneSize => {
            return {
                ...droneSize,
                checked: false,
            }
        }));
    });

    const { droneList: rows, rowCount } = useDrone(
        { ...paginationfilters, ...paginationModel },
        [paginationModel.page, paginationfilters]
    );

    const handleAddClick = () => navigate('/dashboard/manage-drone-form');

    const handleDroneTypeCheckedAllChange = ({ target }) => {
        const checked = target.checked;
        const droneTypeCheckedList = checked ? droneTypeList.map(droneType => droneType.type_name) : [];

        setDroneTypeCheckedAll(checked);
        setDroneTypeList(prev => prev.map(droneType => {
            return {
                ...droneType,
                checked,
            }
        }));
        setFilters(prev => {
            return {
                ...prev,
                droneType: droneTypeCheckedList
            }
        })
    }

    const handleDroneSizeCheckedAllChange = ({ target }) => {
        const checked = target.checked;
        const droneSizeCheckedList = checked ? droneSizeList.map(droneSize => droneSize.size_name) : [];

        setDroneSizeCheckedAll(checked);
        setDroneSizeList(prev => prev.map(droneSize => {
            return {
                ...droneSize,
                checked,
            }
        }));
        setFilters(prev => {
            return {
                ...prev,
                droneSize: droneSizeCheckedList
            }
        })
    }

    // TODO: แก้ filter checkbox หน้าอื่น
    const handleFilterChange = ({ target }) => {
        const { name, value, type, checked } = target;
        let currentValue;
        console.log(value);

        if (type === "checkbox" && name === "droneType") {
            setDroneTypeCheckedAll(false);
            setDroneTypeList(prev => {
                const index = prev.findIndex(dt => dt.type_name === value);
                const newDroneType = prev[index];

                newDroneType.checked = checked;

                prev[index] = newDroneType;

                return [...prev]
            });
        }

        if (type === "checkbox" && name === "droneSize") {
            setDroneSizeCheckedAll(false);
            setDroneSizeList(prev => {
                const index = prev.findIndex(ds => ds.size_name === value);
                const newDroneSize = prev[index];

                newDroneSize.checked = checked;

                prev[index] = newDroneSize;

                return [...prev]
            });
        }

        setFilters(prev => {

            currentValue = value;

            if (type === "checkbox" && name === "droneType") {
                const newDroneTypeList = checked
                    ? [...prev.droneType, value]
                    : prev.droneType.filter(dt => dt !== value)

                if (newDroneTypeList.length === droneTypeList.length) {
                    setDroneTypeCheckedAll(true);
                }

                currentValue = newDroneTypeList;
            }

            if (type === "checkbox" && name === "droneSize") {
                const newDroneSizeList = checked
                    ? [...prev.droneSize, value]
                    : prev.droneSize.filter(ds => ds !== value)

                if (newDroneSizeList.length === droneSizeList.length) {
                    setDroneSizeCheckedAll(true);
                }

                currentValue = newDroneSizeList;
            }

            return {
                ...prev,
                [name]: currentValue,
            }
        });
    }

    const handleSearchClick = () => {
        console.log(filters);
        setPaginationFilters({ ...filters });
        resetPage();
    }

    const handleClearClick = () => {
        setDroneTypeCheckedAll(false);
        setDroneSizeCheckedAll(false);
        setDroneTypeList(prev => prev.map(droneType => {
            return {
                ...droneType,
                checked: false,
            }
        }));
        setDroneSizeList(prev => prev.map(droneSize => {
            return {
                ...droneSize,
                checked: false,
            }
        }));
        setFilters({ ...initFilters });
        setPaginationFilters({ ...initFilters });
        resetPage();
    }

    return (
        <div className="manage-drone">
            <Grid container className="filters">
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>ค้นหา</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            name="search"
                            value={filters.search}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                </Grid>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>ประเภท</Typography>
                    </Grid>
                    <Grid item md={11}>
                        <FormControlLabel
                            control={<Checkbox />}
                            label="ทั้งหมด"
                            checked={droneTypeCheckedAll}
                            onChange={handleDroneTypeCheckedAllChange}
                        />
                        {droneTypeList.map(droneType => (
                            <FormControlLabel
                                key={droneType.id}
                                control={<Checkbox />}
                                label={droneType.type_name}
                                name="droneType"
                                checked={droneType.checked}
                                value={droneType.type_name}
                                onChange={handleFilterChange}
                            />
                        ))}
                    </Grid>
                </Grid>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>ขนาด</Typography>
                    </Grid>
                    <Grid item md={11}>
                        <FormControlLabel
                            control={<Checkbox />}
                            label="ทั้งหมด"
                            checked={droneSizeCheckedAll}
                            onChange={handleDroneSizeCheckedAllChange}
                        />
                        {droneSizeList.map(droneSize => (
                            <FormControlLabel
                                key={droneSize.id}
                                control={<Checkbox />}
                                label={droneSize.size_name}
                                name="droneSize"
                                checked={droneSize.checked}
                                value={droneSize.size_name}
                                onChange={handleFilterChange}
                            />
                        ))}
                    </Grid>
                </Grid>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>วันที่เพิ่ม</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateFrom"
                            value={filters.dateFrom}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>From</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateTo"
                            value={filters.dateTo}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>To</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className="options">
                <Grid item md={1}></Grid>
                <Grid
                    item xs={12} md={6}
                    sx={{
                        '& button': { width: '25%' },
                        '& :not(:nth-of-type(1))': { ml: 2 }
                    }}
                >
                    <OptionButton
                        text='ค้นหา'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleSearchClick}
                    />
                    <OptionButton
                        text='ล้าง'
                        bgColor='var(--options-btn-bg-color-2)'
                        hoverColor='var(--options-btn-bg-hover-2)'
                        textColor='gray'
                        onClick={handleClearClick}
                    />
                    <OptionButton
                        text='เพิ่ม'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleAddClick}
                    />
                </Grid>

                <ExcelButton modelName="Drone" importCallback={handleClearClick} />
            </Grid>

            <DataTable
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                pageProps={paginationModel}
            />
        </div>
    )
}

export default ManageDrone

const DroneStatus = ({ status }) => {
    const currentStatus = droneStatusList[status];
    return (
        <div className={`drone-status ${currentStatus.value}`}>
            {currentStatus.label}
        </div>
    )
}