import { useCallback, useEffect, useState } from 'react'

import { flyingAreaServices } from '../services/flyingAreaServices';

import useErrorHandler from '../hooks/useErrorHandler'

function useAreaBoundary(filters = {}, dependency = []) {
    const { errorHandler } = useErrorHandler();

    const [areaBoundaryList, setAreaBoundaryList] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const getAllAreaBoundary = useCallback(async (currentFilters) => {
        try {
            const res = await flyingAreaServices.getAllAreaBoundary(currentFilters);
            console.log("getAllAreaBoundary => ", res);
            if (res.status === 200) {
                setAreaBoundaryList(res.data.data);
                setRowCount(res.data.rowCount);
            }
        } catch (error) {
            console.log(error);
            errorHandler('error: getAllAreaBoundary', error.response.status);
        }
    }, []);

    useEffect(() => {
        getAllAreaBoundary(filters)
    }, dependency);

    return { areaBoundaryList, rowCount };
}

export default useAreaBoundary