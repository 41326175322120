import { useAxios } from "../guard/axiosConfig"

export const flyingAreaServices = {
    getAllAreaBoundary,
    getAllAreaBoundaryForMap,
    getAreaBoundaryById,
    createAreaBoundary,
    updateAreaBoundary,
    updateAreaBoundaryStatus,
    deleteAreaBoundaryById,
    getAllFlyingArea,
    getFlyingAreaById,
    updateFlyingArea,
    updateFlyingAreaStatus,
    deleteFlyingAreaById,
}

const route = '/flyingArea';

function getAllAreaBoundary(filters) {
    return useAxios.get(`${route}/getAllAreaBoundary`, { params: filters });
}

function getAllAreaBoundaryForMap(filters) {
    return useAxios.get(`${route}/getAllAreaBoundaryForMap`, { params: filters });
}

function getAreaBoundaryById(id) {
    return useAxios.get(`${route}/getAreaBoundaryById/${id}`);
}

function createAreaBoundary(data) {
    const formData = new FormData();
    formData.append('target_area', data.target_area);
    formData.append('file', data.file);

    return useAxios.post(`${route}/createAreaBoundary`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function updateAreaBoundary(data) {
    const formData = new FormData();
    formData.append('id', data.id);
    formData.append('target_area', data.target_area);
    formData.append('file', data.file);

    return useAxios.put(`${route}/updateAreaBoundary`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

function updateAreaBoundaryStatus(data) {
    return useAxios.put(`${route}/updateAreaBoundaryStatus`, data);
}

function deleteAreaBoundaryById(id) {
    return useAxios.delete(`${route}/deleteAreaBoundaryById/${id}`);
}

function getAllFlyingArea(filters) {
    return useAxios.get(`${route}/getAllFlyingArea`, { params: filters });
}

function getFlyingAreaById(id) {
    return useAxios.get(`${route}/getFlyingAreaById/${id}`);
}

function updateFlyingArea(data) {
    return useAxios.put(`${route}/updateFlyingArea`, data);
}

function updateFlyingAreaStatus(data) {
    return useAxios.put(`${route}/updateFlyingAreaStatus`, data);
}

function deleteFlyingAreaById(id) {
    return useAxios.delete(`${route}/deleteFlyingAreaById/${id}`);
}