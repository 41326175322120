import React, { useEffect, useMemo, useState } from 'react'
import './FlightPlanForm.css'

import { Box, FormControl, Grid, IconButton, InputAdornment, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@mui/material'
import { Add, Close, CloudUpload } from '@mui/icons-material'

import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { MapContainer, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

import KML from 'react-leaflet-kml'

import moment from "moment";

import OptionButton from '../../components/OptionButton/OptionButton'
import DragAndDrop from '../../components/DragAndDrop/DragAndDrop'
import FlightInfo from './Tabs/FlightInfo/FlightInfo'
import FlightRoute from './Tabs/FlightRoute/FlightRoute'
import FlightReportFile from './Tabs/FlightReportFile/FlightReportFile'
import FlightRefenceUrl from './Tabs/FlightRefenceUrl/FlightRefenceUrl'
import FlightZipFile from './Tabs/FlightZipFile/FlightZipFile'

import { openStreetMapConfig, thaiCenter } from '../../constants/mapConstants'

import { flightPlanServices } from '../../services/flightPlanServices'
import { flightServices } from '../../services/flightServices'

import Swal from 'sweetalert2'

import useErrorHandler from '../../hooks/useErrorHandler'
import useKmlGeoJson from '../../hooks/useKmlGeoJson'
import useFile from '../../hooks/useFile'
import useFlightDataList from '../../hooks/useFlightDataList'
import FlightDataListProvider from '../../contexts/FlightDataListContext'

const fileTypes = { "application/vnd": [".kml", ".kmz"] };
const flightPlanStatusList = [
    {
        label: 'ยังไม่ดำเนินการ',
        value: 'notimplemented',
    },
    {
        label: 'กำลังดำเนินการ',
        value: 'inprogress',
    },
    {
        label: 'เสร็จสิ้นภารกิจ',
        value: 'complete',
    },
    {
        label: 'ล้มเหลว',
        value: 'failed',
    },
    {
        label: 'ไม่ระบุ',
        value: 'notspecified',
    },
];

function FlightPlanForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isEdit, setIsEdit] = useState(false);
    const [flightPlan, setFlightPlan] = useState({
        flightMissionCode: '',
        missionId: '',
        targetAreaId: '',
        startDate: '',
        endDate: '',
        commandInformation: '',
        flightMissionName: '',
        leaderId: '',
        userId: '',
        flightPlanStatus: '',
        progressPercent: 0,
        flightRouteName: '',
    });

    const [file, setFile] = useState(null);

    const { flyingAreaList, missionList, userList } = useFlightDataList();
    const { errorHandler } = useErrorHandler();
    const { createFile } = useFile();
    const {
        kml, setKml,
        center, setCenter
    } = useKmlGeoJson(file);

    useEffect(() => {
        const getFlightMissionPlanById = async () => {
            try {
                const res = await flightPlanServices.getFlightMissionPlanById(id);
                console.log(res);
                if (res.status === 200) {
                    const data = res.data.data;
                    setFlightPlan({
                        flightMissionCode: data.flight_mission_code,
                        missionId: data.MissionId,
                        targetAreaId: data.FlyingAreaId,
                        startDate: data.start_date,
                        endDate: data.end_date,
                        commandInformation: data.command_information,
                        flightMissionName: data.flight_mission_name,
                        leaderId: data.LeaderId,
                        userId: data.UserId,
                        flightPlanStatus: data.flight_plan_status,
                        progressPercent: data.progress_percent,
                        flightRouteName: data.flight_route_name,
                    });

                    const currentParams = Object.fromEntries([...searchParams]);
                    setSearchParams({ ...currentParams, flyingArea: data.FlyingAreaId });

                    if (data.flight_route_boundary_file) {
                        const fileData = await createFile(data.flight_route_boundary_file);
                        setFile(fileData);
                    }
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getFlightMissionPlanById',
                    status,
                    data.message,
                    false,
                    goToDashboard
                );
            }
        }

        id && getFlightMissionPlanById();
    }, [id])

    const handleEditClick = () => setIsEdit(true);

    const handleDeleteFile = () => {
        setFile(null);
        setCenter(thaiCenter);
        // setGeoJson(null);
        setKml(null);
    };

    const handleFileChange = (acceptedFiles) => {
        console.log(acceptedFiles);
        if (acceptedFiles.length === 0) return;

        setFile({
            data: acceptedFiles[0],
            originalname: acceptedFiles[0].name,
        });
    };

    const handleDataChange = ({ target }) => {
        setFlightPlan(prev => {
            return {
                ...prev,
                [target.name]: target.value,
            }
        })
    }

    const goToDashboard = () => navigate('/dashboard/flight-plan');
    const pushFormID = (id) => navigate(`/dashboard/flight-plan-form/${id}`);

    const handleSaveClick = (e) => {
        e.preventDefault();
        const data = {
            flightMissionPlan: {
                missionId: flightPlan.missionId,
                targetAreaId: flightPlan.targetAreaId,
                start_date: flightPlan.startDate,
                end_date: flightPlan.endDate,
                command_information: flightPlan.commandInformation,
                flight_mission_name: flightPlan.flightMissionName,
                leaderId: flightPlan.leaderId,
                userId: flightPlan.userId,
                flight_plan_status: flightPlan.flightPlanStatus,
                progress_percent: flightPlan.progressPercent,
                flight_route_name: flightPlan.flightRouteName,
            },
            file: file?.data,
        }
        console.log(data);

        if (!id) {
            creatFlightPlan(data);
        } else {
            updateFlightPlan(data);
        }
    }

    const creatFlightPlan = async (data) => {
        try {
            const res = await flightPlanServices.createFlightMissionPlan(data);
            console.log(res);
            if (res.status === 201) {
                Swal.fire({
                    title: "บันทึกแผนการบินสำเร็จ",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        pushFormID(res.data.id);
                    }
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createFlightMissionPlan',
                status,
                data.message
            );
        }
    }

    const updateFlightPlan = async (data) => {
        try {
            const res = await flightPlanServices.updateFlightMissionPlan({
                ...data,
                flightMissionPlan: { ...data.flightMissionPlan, id }
            });
            console.log(res);
            if (res.status === 201) {
                Swal.fire({
                    title: "แก้ไขแผนการบินสำเร็จ",
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setIsEdit(false);
                    }
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: updateFlightMissionPlan',
                status,
                data.message
            );
        }
    }

    return !flightPlan.flightMissionCode || isEdit ? (
        <form onSubmit={handleSaveClick} className="flight-plan-form">
            <Grid container gap={3}>
                <Grid container item xs={12}>
                    <Grid item xs={6}>
                        <TextField
                            required
                            disabled
                            size="small"
                            placeholder="รหัสแผนบิน"
                            value={flightPlan.flightMissionCode}
                            sx={{ "& input": { fontWeight: 700 } }}
                        />
                    </Grid>

                    <Grid container item xs={6} display="flex" justifyContent="flex-end" gap={1}>
                        <OptionButton
                            text="บันทึก"
                            type="submit"
                            bgColor="var(--options-btn-bg-color-5)"
                            hoverColor="var(--options-btn-bg-hover-5)"
                        />
                    </Grid>
                </Grid>

                <Grid container item xs={12} className="form-section">
                    <Typography className="header">ข้อมูลทั่วไป</Typography>
                    <Grid container item xs={6} gap={2}>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>ประเภทภารกิจ</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <FormControl fullWidth size="small">
                                    <Select
                                        required
                                        name="missionId"
                                        value={flightPlan.missionId}
                                        onChange={handleDataChange}
                                    >
                                        {missionList.map(mission =>
                                            <MenuItem
                                                key={mission.id}
                                                value={mission.id}
                                                disabled={!mission.table_status}
                                            >
                                                {mission.mission_name}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>พื้นที่เป้าหมาย</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <FormControl fullWidth size="small">
                                    <Select
                                        required
                                        name="targetAreaId"
                                        value={flightPlan.targetAreaId}
                                        onChange={handleDataChange}
                                    >
                                        {flyingAreaList.map(flyingArea =>
                                            <MenuItem
                                                key={flyingArea.id}
                                                value={flyingArea.id}
                                                disabled={!flyingArea.table_status}
                                            >
                                                {flyingArea.target_area}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>วันเริ่มต้น-สิ้นสุด</Typography>
                            </GridHeader>
                            <Grid item xs={8} display="flex" alignItems="center">
                                <TextField
                                    fullWidth
                                    required
                                    size="small"
                                    type="date"
                                    name="startDate"
                                    value={moment(flightPlan.startDate).format('yyyy-MM-DD')}
                                    onChange={handleDataChange}
                                />
                                <span style={{ margin: '0 6px' }}>-</span>
                                <TextField
                                    fullWidth
                                    required
                                    size="small"
                                    type="date"
                                    name="endDate"
                                    value={moment(flightPlan.endDate).format('yyyy-MM-DD')}
                                    onChange={handleDataChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader alignItems="flex-start">
                                <Typography>ข้อมูลสั่งการ</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    multiline
                                    minRows={3}
                                    maxRows={3}
                                    placeholder="อธิบายขอบเขตงาน หรือหมายเหตุแผนงาน พอสังเขป"
                                    name="commandInformation"
                                    value={flightPlan.commandInformation}
                                    onChange={handleDataChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container item xs={6} gap={2}>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>ชื่อภารกิจ</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    required
                                    size="small"
                                    name="flightMissionName"
                                    value={flightPlan.flightMissionName}
                                    onChange={handleDataChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>หัวหน้าชุดบิน</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <FormControl fullWidth size="small">
                                    <Select
                                        required
                                        name="leaderId"
                                        value={flightPlan.leaderId}
                                        onChange={handleDataChange}
                                    >
                                        {userList.map(user =>
                                            <MenuItem
                                                key={user.id}
                                                value={user.id}
                                                disabled={!user.table_status}
                                            >
                                                {`${user.first_name} ${user.last_name}`}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>ผู้บริหารแผนบิน</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <FormControl fullWidth size="small">
                                    <Select
                                        required
                                        name="userId"
                                        value={flightPlan.userId}
                                        onChange={handleDataChange}
                                    >
                                        {userList.map(user =>
                                            <MenuItem
                                                key={user.id}
                                                value={user.id}
                                                disabled={!user.table_status}
                                            >
                                                {`${user.first_name} ${user.last_name}`}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>สถานะแผนบิน</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <FormControl fullWidth size="small">
                                    <Select
                                        required
                                        name="flightPlanStatus"
                                        value={flightPlan.flightPlanStatus}
                                        onChange={handleDataChange}
                                    >
                                        {flightPlanStatusList.map(status =>
                                            <MenuItem key={status.value} value={status.value}>
                                                {status.label}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>ความคืบหน้า</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                %
                                            </InputAdornment>
                                        )
                                    }}
                                    name="progressPercent"
                                    value={flightPlan.progressPercent}
                                    onChange={handleDataChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} className="file-upload-section">
                    <Grid container item xs={6} gap={2}>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>ชื่อเส้นขอบเขตการบิน</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    name="flightRouteName"
                                    value={flightPlan.flightRouteName}
                                    onChange={handleDataChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="file-upload">
                            <div className="upload-header">
                                <CloudUpload className="icon" />
                                <Typography className="header-text">
                                    อัปโหลดเส้นเขตการบิน (ไฟล์ชนิด .KML .KMZ)
                                </Typography>
                            </div>
                            <DragAndDrop fileTypes={fileTypes} maxFiles={1} onDrop={handleFileChange}>
                                {file && (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography>{file.originalname}</Typography>
                                        <IconButton onClick={handleDeleteFile}>
                                            <Close />
                                        </IconButton>
                                    </div>
                                )}
                            </DragAndDrop>
                        </Grid>
                    </Grid>
                    <Grid container item xs={6}>
                        <Grid item xs={12}>
                            {
                                file && (
                                    <MapContainer center={center} zoom={10} style={{ height: '100%', margin: '0 16px' }} key={center.toString()}>
                                        <TileLayer {...openStreetMapConfig} />
                                        {kml && <KML kml={kml} />}
                                    </MapContainer>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </form>
    ) : (
        <FlightPlanResult
            flightPlan={flightPlan}
            userList={userList}
            flyingAreaList={flyingAreaList}
            missionList={missionList}
            handleEditClick={handleEditClick}
        />
    )
}

export default withFlightDataListProvider(FlightPlanForm)

function withFlightDataListProvider(WrappedComponent) {
    return (props) => (
        <FlightDataListProvider>
            <WrappedComponent {...props} />
        </FlightDataListProvider>
    );
}

const GridHeader = ({ children, alignItems }) => {
    return (
        <Grid
            item xs={3}
            display="flex"
            alignItems={alignItems || "center"}
            justifyContent="flex-end"
            sx={{ "& *": { fontWeight: "700" } }}
        >
            {children}
        </Grid>
    )
}

const FlightPlanResult = ({ flightPlan, userList, missionList, flyingAreaList, handleEditClick }) => {
    return (
        <div className="flight-plan-form">
            <Grid container gap={3}>
                <Grid container item xs={12}>
                    <Grid item xs={6}>
                        <TextField
                            required
                            disabled
                            size="small"
                            placeholder="รหัสแผนบิน"
                            value={flightPlan.flightMissionCode}
                            sx={{ "& input": { fontWeight: 700 } }}
                        />
                    </Grid>

                    <Grid container item xs={6} display="flex" justifyContent="flex-end" gap={1}>
                        <OptionButton
                            text="แก้ไข"
                            onClick={handleEditClick}
                            bgColor="var(--options-btn-bg-color-5)"
                            hoverColor="var(--options-btn-bg-hover-5)"
                        />
                    </Grid>
                </Grid>

                <Grid container item xs={12} className="form-section" alignItems="flex-start">
                    <Typography className="header">ข้อมูลทั่วไป</Typography>
                    <Grid container item xs={6} gap={2}>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>ประเภทภารกิจ</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <Typography>
                                    {missionList.find(
                                        mission => mission.id === flightPlan.missionId
                                    )?.mission_name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>พื้นที่เป้าหมาย</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <Typography>
                                    {flyingAreaList.find(
                                        flyingArea => flyingArea.id === flightPlan.targetAreaId
                                    )?.target_area}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>วันเริ่มต้น-สิ้นสุด</Typography>
                            </GridHeader>
                            <Grid item xs={8} display="flex" alignItems="center">
                                <Typography>
                                    {moment(flightPlan.startDate).format('DD/MM/yyyy')}
                                </Typography>
                                <span style={{ margin: '0 6px' }}>-</span>
                                <Typography>
                                    {moment(flightPlan.endDate).format('DD/MM/yyyy')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader alignItems="flex-start">
                                <Typography>ข้อมูลสั่งการ</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <Typography>
                                    {flightPlan.commandInformation}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container item xs={6} gap={2}>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>ชื่อภารกิจ</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <Typography>
                                    {flightPlan.flightMissionName}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>หัวหน้าชุดบิน</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <Typography>
                                    {(() => {
                                        const user = userList.find(user => user.id === flightPlan.leaderId);
                                        return `${user?.first_name} ${user?.last_name}`
                                    })()}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>ผู้บริหารแผนบิน</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <Typography>
                                    {(() => {
                                        const user = userList.find(user => user.id === flightPlan.userId);
                                        return `${user?.first_name} ${user?.last_name}`
                                    })()}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>สถานะแผนบิน</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <Typography>
                                    {flightPlanStatusList.find(
                                        status => status.value === flightPlan.flightPlanStatus
                                    )?.label}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} gap={1}>
                            <GridHeader>
                                <Typography>ความคืบหน้า</Typography>
                            </GridHeader>
                            <Grid item xs={8}>
                                <Typography>
                                    {`${flightPlan.progressPercent} %`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <FlightSection flightMissionCode={flightPlan.flightMissionCode} />

            </Grid>
        </div>
    )
}

const FlightSection = ({ flightMissionCode }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { errorHandler } = useErrorHandler();

    const [flights, setFlights] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        if (searchParams.has("tabIndex")) {
            setTabIndex(Number(searchParams.get("tabIndex")));
        }
    }, []);

    useEffect(() => {
        if (flights.length) {
            const flightCode = flights[tabIndex].flight_code;
            const currentParams = Object.fromEntries([...searchParams]);
            setSearchParams({ ...currentParams, flightCode, tabIndex });
        }
    }, [flights]);

    useEffect(() => {
        const getAllFlightsByFlightMissionCode = async () => {
            try {
                const res = await flightServices.getAllFlightsByFlightMissionCode(flightMissionCode);
                console.log(res);
                if (res.status === 200) {
                    setFlights([...res.data.data]);
                }
            } catch (error) {
                console.log(error);
                const { status, data } = error.response;
                errorHandler(
                    'error: getAllFlightsByFlightMissionCode',
                    status,
                    data.message
                );
            }
        }

        getAllFlightsByFlightMissionCode();
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        const flightCode = flights[newValue].flight_code;
        const currentParams = Object.fromEntries([...searchParams]);
        setSearchParams({ ...currentParams, flightCode, tabIndex: newValue });
    };

    const handleAddFlightClick = () => {
        Swal.fire({
            title: `คุณต้องการเพิ่มเที่ยวบินที่ ${flights.length + 1} หรือไม่?`,
            text: 'เมื่อยืนยันแล้วจะไม่สามารถลบเที่ยวบินได้',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'var(--options-btn-bg-color-5)',
            confirmButtonText: 'ยืนยันการเพิ่ม',
            cancelButtonColor: 'var(--options-btn-bg-color-6)',
            cancelButtonText: 'ยกเลิกการเพิ่ม',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                addFlightForm();
            }
        })
    }

    const addFlightForm = async () => {
        try {
            const res = await flightServices.createFlight({ flightMissionCode });
            console.log(res);
            if (res.status === 201) {
                setFlights(prev => {
                    return [...prev, res.data]
                });
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: createFlight',
                status,
                data.message
            );
        }
    }

    return (
        <Grid container item xs={12} gap={2} mb={4}>
            <Grid item xs={12} display="flex" alignItems="center">
                <OptionButton
                    bgColor="var(--options-btn-bg-color-1)"
                    hoverColor="var(--options-btn-bg-hover-1)"
                    onClick={handleAddFlightClick}
                >
                    <Add />เพิ่มเที่ยวบิน
                </OptionButton>
                <Box sx={{ maxWidth: 500 }}>
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        value={tabIndex}
                        onChange={handleTabChange}
                    >
                        {flights.map(
                            (flight, index) =>
                                <Tab
                                    key={flight.id || index}
                                    sx={{ fontWeight: 700 }}
                                    label={index + 1}
                                />
                        )}
                    </Tabs>
                </Box>
            </Grid>

            {flights.map(
                (flight, index) => (
                    <TabPanel
                        key={flight.id || index}
                        tabIndex={tabIndex}
                        index={index}
                    />
                )
            )}
        </Grid>
    )
}

const TabPanel = (props) => {
    const { tabIndex, index } = props;

    return tabIndex === index && (
        <FlightForm />
    );
}

const flightTabs = [
    {
        tabName: "ข้อมูลเที่ยวบิน",
        tabContent: <FlightInfo />,
    },
    {
        tabName: "เส้นทางบิน KMZ",
        tabContent: <FlightRoute />,
    },
    {
        tabName: "ไฟล์รายงาน",
        tabContent: <FlightReportFile />,
    },
    {
        tabName: "ลิงก์เชื่อมโยง",
        tabContent: <FlightRefenceUrl />,
    },
    {
        tabName: "ไฟล์ ZIP",
        tabContent: <FlightZipFile />,
    },
];

const FlightForm = () => {
    const [flightTabIndex, setFlightTabIndex] = useState(0);
    const tabContent = useMemo(() => {
        return flightTabs[flightTabIndex].tabContent;
    }, [flightTabIndex]);

    const handleFlightTabChange = (e, newVal) => setFlightTabIndex(newVal);

    return (
        <Grid container item xs={12}>
            <Grid container item xs={12} mb={4}>
                <Grid container item xs={12} justifyContent="flex-end">
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        value={flightTabIndex}
                        onChange={handleFlightTabChange}
                    >
                        {flightTabs.map(
                            (tab) =>
                                <Tab
                                    key={tab.tabName}
                                    sx={{ fontWeight: 700 }}
                                    label={tab.tabName}
                                />
                        )}
                    </Tabs>
                </Grid>
            </Grid>

            {tabContent}
        </Grid>
    )
}