import React, { useState, useEffect, useRef } from "react";
import "./Calendar.css";

import moment from "moment";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Grid, Box, Typography } from "@mui/material";
import { Description, FileDownload } from "@mui/icons-material";

import CustomToolbar from "./CustomToolbar.js";

import { flightServices } from "../../../services/flightServices";

import useErrorHandler from "../../../hooks/useErrorHandler.js";
import useFile from "../../../hooks/useFile.js";
import useFlightPlan from "../../../hooks/useFlightPlan.js";

import { getCalendarRange } from "../../../utils/index.js";

const localizer = momentLocalizer(moment);
moment.locale("th");

const initFilters = {
  ...getCalendarRange(moment().year(), moment().month() + 1),
}

function CalendarPage() {
  const { downloadFile } = useFile();
  const { errorHandler } = useErrorHandler();

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [filters, setFilters] = useState(initFilters);

  const selectedData = useRef([]);
  const flightMissionCodeList = useRef({});

  const {
    flightPlanList: events,
    setFlightPlanList: setEvents,
  } = useFlightPlan(filters, [filters], (value, setState) => {
    setState(value.map(
      ({
        flight_mission_name: title,
        start_date: start,
        end_date: end,
        flight_mission_code,
        FlyingArea,
      }) => ({
        title,
        start: new Date(start),
        end: new Date(end),
        flight_mission_code,
        flying_area: FlyingArea,
      })
    ));
  });

  const handleDownloadClick = async ({ file }) => await downloadFile(file);

  useEffect(() => {
    console.log("event:", events);
  }, [events]);

  const getAllFlightsByFlightMissionCodes = async (missionCodes) => {
    try {
      const res = await flightServices.getAllFlightsByFlightMissionCodes(missionCodes);
      console.log('getAllFlightsByFlightMissionCodes => ', res);
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (error) {
      console.log(error);
      const { status, data } = error.response;
      errorHandler(
        'error: getAllFlightsByFlightMissionCodes',
        status,
        data.message
      );
      return {};
    }
  }

  const handleSelectEvent = async (event) => {
    const { flight_mission_code } = event;
    // เช็คว่ามี code ใน list ไหม
    const existingFlightMissionCode = flightMissionCodeList.current[flight_mission_code];

    // ถ้าไม่มี (เป็น undefined)
    if (existingFlightMissionCode === undefined) {
      let missionCode = [flight_mission_code];

      // fetch flights จาก flight_mission_code
      const flights = await getAllFlightsByFlightMissionCodes(missionCode);

      // เช็ค flights เป็น empty object ไหม 
      // (ถ้าไม่มี key ใน obj คือเป็น {} (empty obj))
      if (!Object.keys(flights).length) {
        setSelectedEvent(null);
        return;
      }

      // ถ้า flights มีค่า
      const updatedSelectedEvent = {
        ...event,
        flightInfo: [...flights[flight_mission_code]],
      };

      // บันทึก index ของข้อมูลของ flight_mission_code
      flightMissionCodeList.current = {
        ...flightMissionCodeList.current,
        [flight_mission_code]: selectedData.current.length,
      };

      // เพิ่มข้อมูลของ flight_mission_code
      selectedData.current = [...selectedData.current, updatedSelectedEvent];
      setSelectedEvent(updatedSelectedEvent);
    } else {
      // ถ้ามี code ใน list จะได้ index ของข้อมูลมา แล้วดึงจาก selectedData
      const existingFlightInfo = selectedData.current[existingFlightMissionCode];

      setSelectedEvent(existingFlightInfo);
    }
  };

  const handleRangeChange = (newDate) => {
    setEvents([]);
    setFilters(prev => ({
      ...prev,
      startDateRange: moment(newDate.start).format('YYYY-MM-DD'),
      endDateRange: moment(newDate.end).format('YYYY-MM-DD'),
    }));
  }

  const messages = {
    showMore: (total) => `ค้นพบ ${total} รายการ`,
  };

  return (
    <div className="calendar-wrapper" style={{ margin: "50px 50px 0px 50px" }}>
      <Grid container spacing={2} style={{ height: "100%" }}>
        <Grid item xs={12} md={8} style={{ height: "100%" }}>
          <Calendar
            localizer={localizer}
            events={events}
            onRangeChange={handleRangeChange}
            startAccessor="start"
            endAccessor="end"
            messages={messages}
            components={{
              toolbar: CustomToolbar,
            }}
            style={{ height: "100%", width: "100%" }}
            onSelectEvent={handleSelectEvent}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ height: "100%" }}>
            <div
              className="date-info"
              style={{
                height: "100%",
                backgroundImage: `url(/calendar_panel.png)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "2% 2% 0 0",
              }}
            >
              {selectedEvent && selectedEvent.flightInfo.length > 0 ? (
                <div>
                  <div>
                    <Typography
                      variant="h6"
                      style={{ fontSize: "1.5vw", textAlign: "center" }}
                    >
                      {moment(selectedEvent.start).format("DD MMMM YYYY")}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "1.5vw",
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                    >
                      {selectedEvent.title}
                    </Typography>

                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "1.5vw",
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                    >
                      {selectedEvent.flying_area.target_area}
                    </Typography>
                  </div>
                  {selectedEvent.flightInfo &&
                    selectedEvent.flightInfo.map((event, index) => (
                      <div key={event.id}>
                        <Typography style={{ fontSize: "1.2vw", marginTop: "3%" }}>
                          เที่ยวบิน {index + 1}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          {event.FlightInfo && (
                            <div className="time" style={{ width: "30%" }}>
                              <Typography style={{ fontSize: "1vw" }}>
                                {event.FlightInfo.takeoff_time
                                  ? moment(
                                    event.FlightInfo.takeoff_time,
                                    "HH:mm:ss"
                                  ).format("LT")
                                  : "N/A"}{" "}
                                -{" "}
                                {event.FlightInfo.landing_time
                                  ? moment(
                                    event.FlightInfo.landing_time,
                                    "HH:mm:ss"
                                  ).format("LT")
                                  : "N/A"}
                              </Typography>
                            </div>
                          )}

                          <div
                            className="filename"
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            {event.FlightReportFiles &&
                              event.FlightReportFiles.length > 0 ? (
                              event.FlightReportFiles.map((file) => (
                                <div
                                  key={file.id}
                                  style={{ display: "flex", width: "100%" }}
                                >
                                  <Description sx={{ marginLeft: "10px" }} />

                                  <Typography
                                    style={{ fontSize: "1vw", width: "100%" }}
                                  >
                                    {file.file.originalname}
                                  </Typography>
                                  <div
                                    className="download-btn"
                                    style={{
                                      marginLeft: "auto",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                      }}
                                      onClick={() => handleDownloadClick(file)}
                                    >
                                      <FileDownload />
                                      <Typography
                                        style={{
                                          fontSize: "0.4vw",
                                          textAlign: "center",
                                        }}
                                      >
                                        ดาวน์โหลดรายงาน
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <Typography
                                style={{ fontSize: "1vw", textAlign: "start" }}
                              >
                                ไม่มีรายงาน
                              </Typography>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <Typography
                  variant="h6"
                  style={{ fontSize: "1.5vw", width: "100%" }}
                >
                  ไม่พบข้อมูลเที่ยวบิน
                </Typography>
              )}
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
export default CalendarPage;