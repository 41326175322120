import React, { useState } from 'react'
import './ManageFlightArea.css'

import { Box, Checkbox, FormControlLabel, Grid, InputAdornment, TextField, Typography } from '@mui/material'

import { useNavigate } from 'react-router-dom';

import DataTable from '../../components/DataTable/DataTable'
import OptionButton from '../../components/OptionButton/OptionButton';
import DeleteButton from '../../components/TableButton/DeleteButton';
import EditButton from '../../components/TableButton/EditButton';
import StatusButton from '../../components/TableButton/StatusButton';
import ExcelButton from '../../components/ExcelButton/ExcelButton';

import { flyingAreaServices } from '../../services/flyingAreaServices';

import useDataTable from '../../hooks/useDataTable';
import useFlyingArea from '../../hooks/useFlyingArea';
import useTargetType from '../../hooks/useTargetType';

const columns = [
    {
        field: 'row_num',
        headerName: 'ลำดับ',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        sortable: false,
    },
    {
        field: 'target_area',
        headerName: 'พื้นที่เป้าหมาย',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 150,
        sortable: false,
    },
    {
        field: 'target_type_name',
        headerName: 'ประเภทพื้นที่เป้าหมาย',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 70,
        sortable: false,
        valueGetter: (param) => param.row.TargetType.target_type_name
    },
    {
        field: 'flyingBase_num',
        headerName: 'รายการฐานบิน',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 150,
        sortable: false,
    },
    {
        field: 'table_status',
        headerName: 'สถานะ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (param) => <StatusButton data={param.row} service={flyingAreaServices.updateFlyingAreaStatus} />
    },
    {
        field: 'edit',
        headerName: 'แก้ไข',
        headerAlign: 'center',
        type: 'actions',
        sortable: false,
        renderCell: (param) => <EditButton id={param.row.id} formURL="manage-flight-area-form" />
    },
    {
        field: 'delete',
        type: 'actions',
        sortable: false,
        renderCell: (param) => <DeleteButton id={param.row.id} service={flyingAreaServices.deleteFlyingAreaById} />
    },
];

const initFilters = {
    search: '',
    targetTypeName: [],
    dateFrom: '',
    dateTo: '',
}

function ManageFlightArea() {
    const navigate = useNavigate();

    const { paginationModel, resetPage } = useDataTable();

    const [filters, setFilters] = useState(initFilters);
    const [paginationfilters, setPaginationFilters] = useState(initFilters);
    const [checkedAll, setCheckedAll] = useState(false);
    const { targetTypeList, setTargetTypeList } = useTargetType({}, [], (value, setState) => {
        setState(value.map(targetType => {
            return {
                ...targetType,
                checked: false,
            }
        }));
    });

    const { flyingAreaList: rows, rowCount } = useFlyingArea(
        { ...paginationfilters, ...paginationModel },
        [paginationModel.page, paginationfilters]
    );

    const handleAddClick = () => navigate('/dashboard/manage-flight-area-form');

    const handleCheckedAllChange = ({ target }) => {
        const checked = target.checked;
        const targetTypeCheckedList = checked ? targetTypeList.map(targetType => targetType.target_type_name) : [];

        setCheckedAll(checked);
        setTargetTypeList(prev => prev.map(targetType => {
            return {
                ...targetType,
                checked,
            }
        }));
        setFilters(prev => {
            return {
                ...prev,
                targetTypeName: targetTypeCheckedList
            }
        })
    }

    const handleFilterChange = ({ target }) => {
        const { name, value, type, checked } = target;
        console.log(value);

        if (type === "checkbox") {
            setCheckedAll(false);
            setTargetTypeList(prev => {
                const index = prev.findIndex(tt => tt.target_type_name === value);
                const newTargetType = prev[index];

                newTargetType.checked = checked;

                prev[index] = newTargetType;

                return [...prev]
            });
        }

        setFilters(prev => {
            return {
                ...prev,
                [name]: type === "checkbox" ? (
                    checked ? (
                        [...prev.targetTypeName, value]
                    ) : prev.targetTypeName.filter(tt => tt !== value)
                ) : value,
            }
        });
    }

    const handleSearchClick = () => {
        console.log(filters);
        setPaginationFilters({ ...filters });
        resetPage();
    }

    const handleClearClick = () => {
        setCheckedAll(false);
        setTargetTypeList(prev => prev.map(targetType => {
            return {
                ...targetType,
                checked: false,
            }
        }));
        setFilters({ ...initFilters });
        setPaginationFilters({ ...initFilters });
        resetPage();
    }

    return (
        <div className="manage-flight-area">
            <Grid container className="filters" gap={1}>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>ค้นหา</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            name="search"
                            value={filters.search}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                </Grid>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>ประเภทพื้นที่เป้าหมาย</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <FormControlLabel
                            control={<Checkbox />}
                            label="ทั้งหมด"
                            checked={checkedAll}
                            onChange={handleCheckedAllChange}
                        />
                        {targetTypeList.map(targetType => (
                            <FormControlLabel
                                key={targetType.id}
                                control={<Checkbox />}
                                label={targetType.target_type_name}
                                name="targetTypeName"
                                checked={targetType.checked}
                                value={targetType.target_type_name}
                                onChange={handleFilterChange}
                            />
                        ))}
                    </Grid>
                </Grid>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>วันที่เพิ่ม</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateFrom"
                            value={filters.dateFrom}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>From</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateTo"
                            value={filters.dateTo}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>To</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className="options">
                <Grid item md={1}></Grid>
                <Grid
                    item xs={12} md={6}
                    sx={{
                        '& button': { width: '25%' },
                        '& :not(:nth-of-type(1))': { ml: 2 }
                    }}
                >
                    <OptionButton
                        text='ค้นหา'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleSearchClick}
                    />
                    <OptionButton
                        text='ล้าง'
                        bgColor='var(--options-btn-bg-color-2)'
                        hoverColor='var(--options-btn-bg-hover-2)'
                        textColor='gray'
                        onClick={handleClearClick}
                    />
                    <OptionButton
                        text='เพิ่ม'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleAddClick}
                    />
                </Grid>

                <ExcelButton modelName="FlyingArea" importCallback={handleClearClick} />
            </Grid>

            <DataTable
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                pageProps={paginationModel}
            />
        </div>
    )
}

export default ManageFlightArea