import React, { useEffect, useMemo, useState } from 'react'
import './UavMap.css'

import { MapContainer, ScaleControl, TileLayer, ZoomControl, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
import Control from 'react-leaflet-custom-control';

import { Button, ButtonGroup } from '@mui/material';

import { useLocation, useSearchParams } from 'react-router-dom';

import { openStreetMapConfig, satelliteMapConfig, thaiCenter } from '../../constants/mapConstants';

import FlightImageLayer from './FlightImageLayer/FlightImageLayer';
import FlightRouteLayer from './FlightRouteLayer/FlightRouteLayer';
import AreaBoundaryLayer from './AreaBoundaryLayer/AreaBoundaryLayer';
import MapControlPanel from './MapControlPanel';

import MapFiltersProvider from '../../contexts/MapFiltersContext'
import useMapFilters from '../../hooks/useMapFilters';

const optionMenu = [
    {
        label: 'ภาพถ่ายทางอากาศ',
        image: './Icon_MS-AerialPhotography.png',
        tileLayer: <FlightImageLayer />,
    },
    {
        label: 'เส้นทางบิน',
        image: './Icon_MS-FlightPath.png',
        tileLayer: <FlightRouteLayer />,
    },
    {
        label: 'เส้นเขตพื้นที่',
        image: './Icon_MS-BoundaryLine.png',
        tileLayer: <AreaBoundaryLayer />,
    },
    // {
    //     label: 'ภาพถ่ายใต้น้ำ',
    //     image: './Icon_MS-Underwater.png',
    //     tileLayer: <></>,
    // },
]

function UavMap() {
    const [searchParams, setSearchParams] = useSearchParams({ mapType: 'map', mapLayer: 'ภาพถ่ายทางอากาศ' });

    const [openSatellite, setOpenSatellite] = useState(searchParams.get('mapType') === 'satellite');

    const { resetFilters } = useMapFilters();

    const layer = useMemo(() => {
        if (searchParams.get("mapLayer")) {
            const currentLayer = optionMenu.find(menu => menu.label === searchParams.get("mapLayer"));
            return currentLayer.tileLayer;
        }
    }, [searchParams]);

    const handleMapClick = () => {
        setOpenSatellite(false);
        const currentParams = Object.fromEntries([...searchParams]);
        setSearchParams({ ...currentParams, mapType: 'map' });
    }
    const handleSatelliteClick = () => {
        setOpenSatellite(true);
        const currentParams = Object.fromEntries([...searchParams]);
        setSearchParams({ ...currentParams, mapType: 'satellite' });
    }

    const handleLayerClick = (layer) => {
        resetFilters();
        const params = { mapLayer: layer };
        const currentParams = Object.fromEntries([...searchParams]);
        setSearchParams({ ...currentParams, ...params });
    }

    return (
        <MapContainer
            className="uav-map"
            center={thaiCenter}
            zoom={9}
            zoomControl={false}
            attributionControl={false}
        >
            {!openSatellite ? (
                <TileLayer {...openStreetMapConfig} />
            ) : (
                <TileLayer {...satelliteMapConfig} />
            )}

            <ScaleControl imperial={false} position="bottomright" />
            <ZoomControl position="bottomright" />

            <Control prepend position="bottomright">
                <ButtonGroup className="map-type">
                    <Button
                        variant="contained"
                        className={`${searchParams.get("mapType") === "map" && "active"}`}
                        onClick={handleMapClick}
                    >
                        แผนที่
                    </Button>
                    <Button
                        variant="contained"
                        className={`${searchParams.get("mapType") === "satellite" && "active"}`}
                        onClick={handleSatelliteClick}
                    >
                        ดาวเทียม
                    </Button>
                </ButtonGroup>
            </Control>

            <MapSearch />

            {/* <Control position="topleft"> */}
                <MapControlPanel
                    handleLayerClick={handleLayerClick}
                    optionMenu={optionMenu}
                />
            {/* </Control> */}

            {layer}

        </MapContainer>
    )
}

export default withMapFiltersProvider(UavMap)

function withMapFiltersProvider(WrappedComponent) {
    return (props) => (
        <MapFiltersProvider>
            <WrappedComponent {...props} />
        </MapFiltersProvider>
    );
}

function MapSearch() {
    const map = useMap();
    const location = useLocation();
    const isMobile = location.pathname.includes('mapForMobile');

    useEffect(() => {
        const provider = new OpenStreetMapProvider();
        const searchControl = new GeoSearchControl({
            provider,
            position: 'topright',
            autoComplete: !isMobile,
            autoCompleteDelay: 500,
            searchLabel: 'ค้นหาสถานที่',
            style: isMobile ? 'button' : 'bar',
            autoClose: true,
            keepResult: true,
            showMarker: false,
        });

        map.addControl(searchControl);
        return () => map.removeControl(searchControl);
    }, []);

    return null;
}