import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './UserLog.css'

import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material'

import DataTable from '../../components/DataTable/DataTable'
import OptionButton from '../../components/OptionButton/OptionButton';
import ExcelButton from '../../components/ExcelButton/ExcelButton';

import { logServices } from "../../services/logServices";

import { useLocation } from 'react-router-dom';

import useDataTable from '../../hooks/useDataTable';
import useErrorHandler from '../../hooks/useErrorHandler';

const columns = [
    {
        field: 'row_num',
        headerName: 'ลำดับ',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
    },
    {
        field: 'fullname',
        headerName: 'ชื่อ-นามสกุล',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        sortable: false,
    },
    {
        field: 'request_method',
        headerName: 'ฟังชั่นการใช้งาน',
        headerAlign: 'center',
        flex: 1,
        align: 'center',
        sortable: false,
    },
    {
        field: 'detail',
        headerName: 'รายละเอียด',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        sortable: false,
    },
    {
        field: 'createdAt',
        headerName: 'วันและเวลา',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        sortable: false,
    },
];

const initFilters = {
    search: '',
    dateFrom: '',
    dateTo: '',
}

function UserLog() {
    const location = useLocation();

    const { paginationModel, resetPage } = useDataTable();
    const { errorHandler } = useErrorHandler();

    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [filters, setFilters] = useState(initFilters);
    const [paginationfilters, setPaginationFilters] = useState(initFilters);

    const fetchLog = useCallback(async (currentFilters) => {
        console.log('current file', currentFilters);
        try {
            const res = await logServices.getAllLog(currentFilters);
            console.log(res);
            if (res.status === 200) {
                setRows(res.data.data);
                setRowCount(res.data.rowCount);
            }
        } catch (error) {
            console.log(error);
            const { status, data } = error.response;
            errorHandler(
                'error: getAllLog',
                status,
                data.message
            );
        }
    }, []);

    const logType = useMemo(() => {
        const path = location.pathname;

        if (path.includes('user-log')) return 'USER';
        else if (path.includes('admin-log')) return 'ADMIN';

    }, [location.pathname]);

    useEffect(() => {
        fetchLog({ ...paginationfilters, ...paginationModel, role: logType });
    }, [paginationModel.page, paginationfilters, logType])

    const handleFilterChange = ({ target }) => {
        const { name, value } = target;
        console.log(value);

        setFilters(prev => {
            return {
                ...prev,
                [name]: value,
            }
        });
    }

    const handleSearchClick = () => {
        console.log(filters);
        setPaginationFilters({ ...filters });
        resetPage();
    }

    const handleClearClick = () => {
        setFilters({ ...initFilters });
        setPaginationFilters({ ...initFilters });
        resetPage();
    }


    return (
        <div className="user-log">
            <Grid container className="filters" gap={1}>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>ค้นหา</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            name="search"
                            value={filters.search}
                            onChange={handleFilterChange}
                            fullWidth
                            size="small" />
                    </Grid>
                </Grid>
                <Grid container item md={12} display="flex" alignItems="center">
                    <Grid item md={1}>
                        <Typography>วันที่เข้าร่วม</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            name="dateFrom"
                            size="small"
                            value={filters.dateFrom}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>From</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            type="date"
                            size="small"
                            name="dateTo"
                            value={filters.dateTo}
                            onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Box>To</Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className="options">
                <Grid item md={1}></Grid>
                <Grid
                    item xs={12} md={6}
                    sx={{
                        '& button': { width: '25%' },
                        '& :not(:nth-of-type(1))': { ml: 2 }
                    }}
                >
                    <OptionButton
                        text='ค้นหา'
                        bgColor='var(--options-btn-bg-color-1)'
                        hoverColor='var(--options-btn-bg-hover-1)'
                        onClick={handleSearchClick}
                    />
                    <OptionButton
                        text='ล้าง'
                        bgColor='var(--options-btn-bg-color-2)'
                        hoverColor='var(--options-btn-bg-hover-2)'
                        textColor='gray'
                        onClick={handleClearClick}
                    />
                </Grid>

                <ExcelButton modelName="Log" importCallback={handleClearClick} />
            </Grid>

            <DataTable
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                pageProps={paginationModel}
            />
        </div>
    )
}

export default UserLog